import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  RefObject,
} from "react";
import Tour from "@reactour/tour";
import { colors } from "colors";
import { TourStep } from "types";
import { GreenButton } from "styles/shared_styled_comps/components.styled";
import { putUser } from "api/helpers";
import { useNavigate, useLocation } from "react-router-dom";
import { Position } from "@reactour/tour";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import { DealOrCall } from "components/AddDeal/AddDeal.styled";
import {
  GradientButton,
  GradientText,
} from "styles/shared_styled_comps/components.styled";
type TourProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  disabledActions: boolean;
  setDisabledActions: Dispatch<SetStateAction<boolean>>;
  sidebarRef: RefObject<HTMLDivElement>;
  tourPosition: Position;
  setTourPosition: Dispatch<SetStateAction<Position>>;
  setEndTour: Dispatch<SetStateAction<boolean>>;
};

const LeadoffTour = (props: TourProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const [popoverSidebarPositioning, setPopoverSidebarPositioning] =
    useState<number>();
  const [openSkipModal, setOpenSkipModal] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number | null>(null);
  const disabledPrevList = [0, 2, 3, 4, 6, 16, 18, 20];
  const disableNextList = [1, 2, 3, 5, 9, 11, 13, 15, 17, 19];
  const steps = [
    // 0
    {
      selector: ".dashboard-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Welcome to Your Dashboard!</p>
          <p>
            Here's where you'll find a comprehensive overview of your activities
            and insights in the Leadoff.ai platform. For a more detailed view,
            simply hover over the navigation in your left hand sidebar - it will
            automatically extend, revealing all the tools available to you.
            These links will be disabled until you complete the walkthrough.
          </p>
          <span
            style={{
              color: `${colors.lightGray}`,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => setOpenSkipModal(true)}
          >
            Skip
          </span>
        </>
      ),
    },
    // 1
    {
      selector: ".add-deal-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Let's walk through how to add a deal.
          </p>
          <p>
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              Click on the "Add Call/Deal" button
            </span>{" "}
            to enter details about a sales call and the prospect you're engaging
            with. This is the first step in leveraging the power of Leadoff.ai
            to analyze and enhance your sales process.
          </p>
        </>
      ),
    },
    // 2
    {
      selector: ".deal-or-call",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Here you can decide to add a call to an existing deal or create a
            new deal to analyze in the Leadoff.ai platform
          </p>
          <p>
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              Let's add a New Deal.
            </span>{" "}
          </p>
        </>
      ),
    },
    // 3
    {
      selector: ".new-deal",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Here's the New Deal window</p>
          <p>
            This is where you can provide relevant deal details. "Company
            Website" is the main URL for the company you are selling to and will
            enable Leadoff.ai to properly analyze your deal as new calls and
            information are provided. Note that you can choose to integrate
            other systems (Zoom, Salesforce, etc.) for automatic deal/call
            uploads. Now, click
            <strong>"Add Deal"</strong> to trigger our AI deal analysis.
          </p>
        </>
      ),
    },
    // 4
    {
      selector: ".deals-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Here's your deal listing page.</p>
          <p>This is where you can view all of your active deals.</p>
        </>
      ),
    },
    // 5
    {
      selector: ".deal-details",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Let's check out a sample deal</p>
          <p>
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              Click "Deal Details"
            </span>{" "}
            next to the sample deal that was just created.
          </p>
        </>
      ),
    },
    // 6
    {
      selector: ".deals-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Welcome to the Deal Page</p>
          <p>
            This is where you can review all the analysis for your deal and
            interact with Leadoff.ai's Deal Coach.
          </p>
        </>
      ),
    },
    // 7

    {
      selector: ".deal-cycle",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Integrate your CRM here</p>
          <p>
            You can connect your CRM (Salesforce, etc.) to bring Deal Value and
            other information into the Leadoff.ai platform for your deal.
          </p>
        </>
      ),
    },
    // 8

    {
      selector: ".summary",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Summary Tab</p>
          <p>
            Check out this complete summary of information for your deal. Here,
            we consolidate all of the information gathered from your calls into
            your sale methodology of choice (e.g. MEDDPICC).
          </p>
        </>
      ),
    },

    // 9
    {
      selector: ".summary",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Let's check out the Recommendations Tab
          </p>
          <p>
            {" "}
            Click on the
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              "Recommendations"
            </span>{" "}
            tab to see more
          </p>
        </>
      ),
    },
    // 10
    {
      selector: ".recommendations",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>This is the recommendations tab.</p>
          <p>
            Scroll down to check out action items for your deal. In this
            example, we've analyzed opportunities to progress this deal using
            the MEDDPICC sales methodology. When setting up your account, you'll
            be able to select a default sales method of choice.
          </p>
        </>
      ),
    },
    // 11
    {
      selector: ".pre-call-prep",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>This is the pre-call prep tab.</p>
          <p>
            {" "}
            Click on the
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              "Pre-Call Prep"
            </span>{" "}
            tab to see more
          </p>
        </>
      ),
    },
    // 12
    {
      selector: ".pre-call-prep",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Pre-Call Prep Tab.</p>
          <p>
            Check out the Pre-Call Prep. This provides a quick review of
            information about the company and team you're selling to.
          </p>
        </>
      ),
    },
    // 13
    {
      selector: ".deal-coach",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>This is the Deal Coach tab</p>
          <p>
            {" "}
            Click on the
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              "Deal Coach"
            </span>{" "}
            tab to see more
          </p>
        </>
      ),
    },
    // 14
    {
      selector: ".deal-coach",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Deal Coach Tab.</p>
          <p>
            Check out the Deal Coach. We provide this interactive AI coach to
            help provide you with insight and efficiency during your deals. You
            can write chats to it directly, or click on any of the pregenerated
            prompts on the right hand side of the screen. Be sure to try using
            this Deal Coach for both strategic advice and simple assistance
            (e.g. generating a follow up email or linking to the most
            appropriate follow up resource for your prospect) throughout your
            deal.
          </p>
        </>
      ),
    },

    // 15
    {
      selector: ".documents-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Let's check out the resources page
          </p>
          <p>
            {" "}
            Click on
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              "Resources"
            </span>{" "}
            to see more
          </p>
        </>
      ),
    },
    // 16
    {
      selector: ".documents-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>This is the Resources page</p>
          <p>
            You can view and upload important documents for your sales efforts
            here. Our Deal Coach is able to learn from these files and
            incorporate knowledge from them into your conversations and
            insights. Examples of what to include hee things to upload here
            might be:
          </p>
          <ul>
            <li>Case Studies/Customer Stories and other marketing materials</li>
            <li>Demo/Training materials for your product</li>
            <li>Anything about how to follow your company's sales process.</li>
          </ul>
        </>
      ),
    },
    // 17
    {
      selector: ".user-settings",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Let's check out the Integations page
          </p>
          <p>
            Click on your name in the upper right hand corner, then{" "}
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              "Integrations"
            </span>{" "}
            to see more.
          </p>
        </>
      ),
    },
    // 18
    {
      selector: ".documents-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>This is the Integrations page</p>
          <p>
            Here you can connect a variety of applications to share data with
            the Leadoff.ai platform. We highly suggest integrating your call
            system (Zoom, Teams, etc.) of choice to get started.
            <span style={{ color: `${colors.mainGreen}`, fontWeight: "bold" }}>
              {" "}
              If you have any ideas for integrations that you would like to see
              added, please email support@leadoff.ai.
            </span>{" "}
          </p>
        </>
      ),
    },
    // 19
    {
      selector: ".user-settings",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            Let's check out the Account Settings
          </p>
          <p>
            Click on your name in the upper right hand corner, then "Company
            Account" to see more.
          </p>
        </>
      ),
    },
    // 20
    {
      selector: ".documents-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>
            This is the Account Settings page
          </p>
          <p>
            This is where you can manage your team, including adding new team
            members to this account. This will enable you to share insights with
            your manager, teammates and direct reports more easily.
          </p>
        </>
      ),
    },
    // 21
    {
      selector: ".documents-link",
      content: (
        <>
          <p style={{ fontWeight: "bold" }}>Well Done!</p>
          <p>You are ready to use Leadoff.ai</p>
        </>
      ),
    },
  ];

  const query = new URLSearchParams(useLocation().search);
  const newQuery = new URLSearchParams(query.toString());
  const retry = query.get("retry");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== null && window.innerWidth !== undefined) {
        setWindowWidth(window?.innerWidth)
      }
    }

    if (retry) {
      props.setCurrentStep(6);
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== null && window.innerWidth !== undefined) {
        setWindowWidth(window?.innerWidth)
      }
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (
        props.currentStep === 0 ||
        props.currentStep === 4 ||
        props.currentStep === 6 ||
        props.currentStep === 15 ||
        props.currentStep === 16 ||
        props.currentStep === 18 ||
        props.currentStep === 20
      ) {
        setPopoverSidebarPositioning(props.sidebarRef?.current?.offsetWidth);
      } else if (props.currentStep === 1) {
        setPopoverSidebarPositioning(125);
      } else if (props.currentStep === 14) {
        setPopoverSidebarPositioning(270);
      } else {
        setPopoverSidebarPositioning(0);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (props.sidebarRef.current) {
      resizeObserver.observe(props.sidebarRef.current);
    }
    return () => {
      if (props.sidebarRef.current) {
        resizeObserver.unobserve(props.sidebarRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [props.currentStep]);

  useEffect(() => {
    const handlePosition = () => {
      if (props.currentStep >= 7 && props.currentStep <= 14) {
        props.setTourPosition("top");
      }
      if (props.currentStep > 14 && props.currentStep <= 18) {
        props.setTourPosition("right");
      }
      if (props.currentStep > 18 && props.currentStep < 21) {
        props.setTourPosition("left");
      }
      if (props.currentStep === steps.length - 1) {
        props.setTourPosition("center");
      }
    };
    if (!user?.completed_intro_tour) {
      handlePosition();
    }
  }, [props.currentStep]);

  const handleClose = async (userData: object) => {
    await putUser(userData);
    dispatch(startLoading());
    setOpenSkipModal(false);
    props.setIsOpen(false);
    props.setEndTour(true);
    navigate("/app/dashboard");
    dispatch(endLoading());
  };

  const handleDealCoachTop = () => {
    let top;
    // if (props.currentStep === 13) {
    //   top = "-200px"
    // } else if (props.currentStep === 14) {
    //   top = "-380px"
    // }
    return top;
  };

  return (
    <>
      <Tour
        steps={steps}
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        currentStep={props.currentStep}
        setCurrentStep={props.setCurrentStep}
        disabledActions={props.disabledActions}
        setDisabledActions={props.setDisabledActions}
        disableKeyboardNavigation
        position={props.tourPosition}
        showDots={false}
        showCloseButton={true}
        onClickClose={() => {}}
        prevButton={() => {
          let disabled = disabledPrevList.includes(props.currentStep);
          return (
            <GreenButton
              width="fit-content"
              height="fit-content"
              paddingBottom={10}
              paddingTop={10}
              paddingRight={15}
              paddingLeft={15}
              fontSize={15}
              disabled={disabled}
              style={{
                backgroundColor: disabled ? colors.white : "",
                color: disabled ? colors.green : "",
                opacity: disabled ? ".5" : "",
              }}
              onClick={() => {
                props.setCurrentStep(props.currentStep - 1);
              }}
            >
              Back
            </GreenButton>
          );
        }}
        nextButton={() => {
          if (steps.length - 1 === props.currentStep) {
            return (
              <GreenButton
                width="fit-content"
                height="fit-content"
                paddingBottom={10}
                paddingTop={10}
                paddingRight={15}
                paddingLeft={15}
                fontSize={15}
                disabled={disableNextList.includes(props.currentStep)}
                onClick={() => {
                  handleClose({ completed_intro_tour: true });
                }}
              >
                Close
              </GreenButton>
            );
          } else {
            let disabled = disableNextList.includes(props.currentStep);
            return (
              <GreenButton
                width="fit-content"
                height="fit-content"
                paddingBottom={10}
                paddingTop={10}
                paddingRight={15}
                paddingLeft={15}
                fontSize={15}
                disabled={disabled}
                style={{
                  backgroundColor: disabled ? colors.white : "",
                  color: disabled ? colors.green : "",
                  opacity: disabled ? ".5" : "",
                }}
                onClick={() => {
                  props.setCurrentStep(props.currentStep + 1);
                }}
              >
                Next
              </GreenButton>
            );
          }
        }}
        styles={{
          popover: (base) => ({
            ...base,
            backgroundColor: "white",
            color: "black",
            display: props.isOpen ? "block" : "none",
            top: windowWidth && windowWidth <= 1669 ? "" : handleDealCoachTop(),
            left: `${
              popoverSidebarPositioning && popoverSidebarPositioning - 100
            }px`,
          }),
          maskWrapper: (base) => ({
            display: "none",
          }),
          badge: (base) => ({
            ...base,
            backgroundColor: "green",
          }),
          close: (base) => ({
            display: "none",
          }),
        }}
      />
      {openSkipModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100000",
          }}
        >
          <DealOrCall>
            <h2>Are you sure you want to skip the onboarding process?</h2>
            <GradientButton
              onClick={() => handleClose({ completed_intro_tour: true })}
              width="80%"
              height="60px"
              fontSize={14}
              top={150}
            >
              Yes
            </GradientButton>
            <GradientButton
              onClick={() => setOpenSkipModal(false)}
              width="80%"
              height="60px"
              fontSize={14}
              top={70}
            >
              No
            </GradientButton>
          </DealOrCall>
        </div>
      )}
    </>
  );
};

export default LeadoffTour;
