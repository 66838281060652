import React from "react";

const Currency = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="outline/moneys">
      <g id="moneys">
        <path
          id="Vector"
          d="M12.9096 15.6841H5.58463C5.1513 15.6841 4.74295 15.6424 4.37629 15.5591C4.18462 15.5341 3.9513 15.4674 3.70963 15.3758C2.6013 14.9591 1.29297 13.9341 1.29297 11.3841V7.09242C1.29297 4.36742 2.85963 2.80078 5.58463 2.80078H12.9096C15.2096 2.80078 16.693 3.90076 17.093 5.90076C17.168 6.26742 17.2013 6.65908 17.2013 7.09242V11.3841C17.2013 14.1258 15.643 15.6841 12.9096 15.6841ZM5.59295 4.06746C3.54295 4.06746 2.55128 5.0591 2.55128 7.1091V11.4008C2.55128 12.8925 3.0763 13.8091 4.1513 14.2175C4.31796 14.2758 4.4763 14.3174 4.6263 14.3424C4.94297 14.4091 5.25128 14.4424 5.59295 14.4424H12.918C14.968 14.4424 15.9596 13.4508 15.9596 11.4008V7.1091C15.9596 6.7591 15.9346 6.45076 15.8763 6.16743C15.593 4.75076 14.6263 4.06746 12.918 4.06746H5.59295Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_2"
          d="M15.4073 18.1829H8.08227C7.37394 18.1829 6.74059 18.083 6.19893 17.8746C4.97393 17.4163 4.17392 16.4496 3.89892 15.0663C3.85725 14.858 3.92394 14.6413 4.07394 14.4996C4.22394 14.3496 4.44061 14.2913 4.64894 14.3413C4.92394 14.3996 5.23227 14.4329 5.58227 14.4329H12.9073C14.9573 14.4329 15.9489 13.4413 15.9489 11.3913V7.09963C15.9489 6.74963 15.9239 6.44129 15.8656 6.15795C15.8239 5.94962 15.8906 5.74129 16.0323 5.59129C16.1823 5.44129 16.3906 5.37462 16.5989 5.42462C18.5989 5.83295 19.6989 7.31629 19.6989 9.59963V13.8913C19.6989 16.6246 18.1406 18.1829 15.4073 18.1829ZM5.43228 15.6829C5.69894 16.1663 6.09894 16.508 6.64894 16.708C7.04894 16.858 7.53225 16.9329 8.09059 16.9329H15.4156C17.4656 16.9329 18.4573 15.9413 18.4573 13.8913V9.59963C18.4573 8.28296 18.0489 7.40797 17.2073 6.94963C17.2073 6.99963 17.2073 7.04963 17.2073 7.09963V11.3913C17.2073 14.1163 15.6406 15.6829 12.9156 15.6829H5.59059C5.53225 15.6829 5.48228 15.6829 5.43228 15.6829Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_3"
          d="M9.25079 12.0758C7.69246 12.0758 6.42578 10.8091 6.42578 9.25079C6.42578 7.69246 7.69246 6.42578 9.25079 6.42578C10.8091 6.42578 12.0758 7.69246 12.0758 9.25079C12.0758 10.8091 10.8091 12.0758 9.25079 12.0758ZM9.25079 7.67578C8.38413 7.67578 7.67578 8.38413 7.67578 9.25079C7.67578 10.1175 8.38413 10.8258 9.25079 10.8258C10.1175 10.8258 10.8258 10.1175 10.8258 9.25079C10.8258 8.38413 10.1175 7.67578 9.25079 7.67578Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_4"
          d="M4.48438 11.7077C4.14271 11.7077 3.85938 11.4244 3.85938 11.0827V7.41602C3.85938 7.07435 4.14271 6.79102 4.48438 6.79102C4.82604 6.79102 5.10938 7.07435 5.10938 7.41602V11.0827C5.10938 11.4244 4.83437 11.7077 4.48438 11.7077Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_5"
          d="M14.0078 11.7077C13.6661 11.7077 13.3828 11.4244 13.3828 11.0827V7.41602C13.3828 7.07435 13.6661 6.79102 14.0078 6.79102C14.3495 6.79102 14.6328 7.07435 14.6328 7.41602V11.0827C14.6328 11.4244 14.3578 11.7077 14.0078 11.7077Z"
          fill="#DEE2D4"
        />
      </g>
    </g>
  </svg>
);

export default Currency;
