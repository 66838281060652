import React from "react";

const Share = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88715 6.30367C6.78676 6.30367 6.68637 6.26668 6.60711 6.18742C6.45388 6.03419 6.45388 5.78057 6.60711 5.62734L10.9398 1.29461C11.0931 1.14138 11.3467 1.14138 11.4999 1.29461C11.6532 1.44784 11.6532 1.70146 11.4999 1.8547L7.16719 6.18742C7.08794 6.26668 6.98755 6.30367 6.88715 6.30367Z"
        fill="#DEE2D4"
      />
      <path
        d="M11.6435 4.08466C11.4268 4.08466 11.2472 3.90501 11.2472 3.68838V1.54843H9.10722C8.89059 1.54843 8.71094 1.36878 8.71094 1.15215C8.71094 0.935509 8.89059 0.755859 9.10722 0.755859H11.6435C11.8601 0.755859 12.0397 0.935509 12.0397 1.15215V3.68838C12.0397 3.90501 11.8601 4.08466 11.6435 4.08466Z"
        fill="#DEE2D4"
      />
      <path
        d="M7.94493 12.1161H4.77464C1.90553 12.1161 0.679688 10.8902 0.679688 8.02111V4.85082C0.679688 1.9817 1.90553 0.755859 4.77464 0.755859H5.83141C6.04804 0.755859 6.22769 0.935509 6.22769 1.15215C6.22769 1.36878 6.04804 1.54843 5.83141 1.54843H4.77464C2.33881 1.54843 1.47226 2.41498 1.47226 4.85082V8.02111C1.47226 10.4569 2.33881 11.3235 4.77464 11.3235H7.94493C10.3808 11.3235 11.2473 10.4569 11.2473 8.02111V6.96434C11.2473 6.74771 11.427 6.56806 11.6436 6.56806C11.8602 6.56806 12.0399 6.74771 12.0399 6.96434V8.02111C12.0399 10.8902 10.814 12.1161 7.94493 12.1161Z"
        fill="#DEE2D4"
      />
    </svg>
  );
};

export default Share;
