import { colors } from "colors";
import styled from "styled-components";

interface PersonalizedSectionWrapperProps {
  descriptionWidth?: number | null;
  imageTop?: number | null;
  minImageLeft?: number | null;
}

export const PersonalizedSectionWrapper = styled.div<PersonalizedSectionWrapperProps>`
  margin-bottom: 150px;
  padding-right: 0;
  padding-left: 0;
  /* padding-bottom: 300px; */
  background: radial-gradient(
    48.75% 47.31% at 46.83% 45.24%,
    rgba(0, 191, 99, 0.3) 1%,
    #2f2f2f 97.39%
  );
  mark {
    color: ${colors.mainGreen};
  }
  @media (max-width: 906px) {
    button {
      width: 100%;
    }
  }
  .row {
    display: flex;
    margin-bottom: 100px;
    z-index: 100;
    .section-left {
      flex: 0 1 100%;
      position: relative;
      div {
        margin-bottom: 75px;
        h2 {
          margin-top: 20px;
          margin-bottom: 25px;
          span {
            color: ${colors.green};
          }
        }
        h4 {
          margin-bottom: 50px;
          color: ${colors.lightGray};
          width: 100%;
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 75px;
        li {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin-bottom: 15px;
          color: #98a2b3;
          svg {
            margin-right: 10px;
          }
        }
      }
      .image-wrapper {
        position: relative;
        width: fit-content;
        height: fit-content;
        left: unset;
        top: unset;
        img {
          width: 100%;
        }
      }
    }
    .section-right {
      flex: 0 1 30%;
      position: relative;
      display: none;
      img {
        max-width: 750px;
      }
    }
  }
  @media (min-width: 906px) {
    .row {
      .section-left {
        flex: 0 1 50%;
        div {
          h4 {
            margin-bottom: unset;
          }
        }
      }
      .section-right {
        display: block;
        flex: 0 1 50%;
        margin-left: 50px;
        .image-wrapper {
          /* position: absolute; */

          top: ${(props) => props?.imageTop && `${props.imageTop}px`};
          img {
            width: unset;
          }
        }
      }
    }
  }
  @media (min-width: 1375px) {
    .row {
      .section-left {
        div {
          h4 {
            width: 90%;
          }
        }
      }
    }
  }
`;
