import React, { useRef, useEffect, useState } from "react"
import { MarketingBlogPostPageWrapper } from "./MarketingBlog.styled"
import ArrowsPointLeft from "static/svgs/ArrowsPointLeft"
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection"
import BlackInstagram from "static/svgs/BlackInstagram"
import LinkedinBlack from "static/svgs/LinkedinBlack"
import TwitterBlack from "static/svgs/TwitterBlack"
import TwitterLabel from "static/svgs/TwitterLabel"
import LinkedinLabel from "static/svgs/LinkedinLabel"
import FacebookLabel from "static/svgs/FacebookLabel"
import RedditLabel from "static/svgs/RedditLabel"
import { useParams } from "react-router-dom"
import { StructuredText } from "react-datocms"
import { Helmet } from "react-helmet"
import { BlogPost } from "marketingTypes"

type Props = {}

const MarketingBlogPost = (props: Props) => {
  const descriptionTopRef = useRef<HTMLHeadingElement>(null)
  const [topRef, setTopRef] = useState<number | null>(null)
  const [post, setPost] = useState<BlogPost | null>(null)
  const [structuredData, setStructuredData] = useState<object | {}>({})
  const { postId } = useParams()

  useEffect(() => {
    async function getPost() {
      try {
        const data = await fetch("https://graphql.datocms.com/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_DATO_GRAPHQL_API_KEY}`,
          },
          body: JSON.stringify({
            query: `
            {
                blogPost(filter: {id: {eq: "${postId}"}}) {
                    title
                    id
                    description
                    date
                    title
                    blogPostAuthor {
                      authorImage {
                        url
                      }
                      authorName
                      facebookLink
                      instagramLink
                      linkedinLink
                      redditLink
                      twitterLink
                    }
                    content {
                    value
                  }
                }
              }
           `,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setPost(res.data.blogPost)
            setStructuredData({
              "@context": "https://schema.org",
              "@type": "Article",
              headline: `${res.data.blogPost.title}`,
              description: `${res.data.blogPost.description}`,
              image:
                "https://www.datocms-assets.com/127817/1716136774-logo.png",
              datePublished: new Date(res.data.blogPost.date).toISOString(),
              author: {
                "@type": "Person",
                name: `${res.data.blogPost.blogPostAuthor.authorName}`,
                url: `${res.data.blogPost.blogPostAuthor.linkedinLink}`,
              },
              publisher: {
                "@type": "Organization",
                name: "Leadoff.ai",
                url: "https://leadoff.ai/",
              },
            })
          })
      } catch (error) {
        console.log(error)
      }
    }
    getPost()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (descriptionTopRef.current) {
        setTopRef(descriptionTopRef.current.offsetTop)
      }
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <MarketingBlogPostPageWrapper topRef={topRef}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="inner-wrapper">
        <div className="heading-wrapper">
          <div className="heading-left">
            <h1>
              Leadoff <span>Blog</span>
            </h1>
            <h4 ref={descriptionTopRef}>
              Navigating Challenges and Coaching to Success in the Sales World
            </h4>
          </div>
          <div className="heading-right">
            <ArrowsPointLeft />
          </div>
        </div>
        <hr />
        <div className="content-wrapper">
          <h2>{post?.title}</h2>
          <div className="author">
            <div className="inner">
              <p>by: &nbsp;</p>
              <div className="info-wrapper">
                <p>{post?.blogPostAuthor.authorName}</p>
              </div>
              <div className="image-wrapper">
                <img src={post?.blogPostAuthor?.authorImage?.url} />
              </div>
            </div>
          </div>
          {/* <div>
            {post?.content?.value?.document?.children.map((child: any) => {
              console.log(child)
              if (child.children[0].marks === undefined) {
                return <p>{child.children[0].value}</p>
              } else {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: child.children[0].value,
                    }}
                  />
                )
              }
            })}
          </div> */}
          <StructuredText data={post?.content?.value} />
          <div className="author-section">
            <div className="left">
              <p>Author</p>
              <div className="inner">
                <div className="image-wrapper">
                  <img src={post?.blogPostAuthor?.authorImage?.url} />
                </div>
                <div className="info-wrapper">
                  <p>{post?.blogPostAuthor?.authorName}</p>
                  <div className="socials-wrapper">
                    <a target="_blank" href={post?.blogPostAuthor?.twitterLink}>
                      <TwitterBlack />
                    </a>
                    <a
                      target="_blank"
                      href={post?.blogPostAuthor?.linkedinLink}
                    >
                      <LinkedinBlack />
                    </a>
                    <a
                      target="_blank"
                      href={post?.blogPostAuthor?.instagramLink}
                    >
                      <BlackInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="right">
              <p>Share this article</p>
              <div className="socials-wrapper">
                <TwitterLabel />
                <FacebookLabel />
                <RedditLabel />
                <LinkedinLabel />
              </div>
            </div>
          </div>
        </div>
        <StepUpSection />
      </div>
    </MarketingBlogPostPageWrapper>
  )
}

export default MarketingBlogPost
