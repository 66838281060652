import React, { FC, useEffect, useState } from "react";
import { MaintenanceModeWrapper } from "./MaintenanceMode.styled";
import { GreenButton } from "styles/shared_styled_comps/components.styled";
import { useNavigate } from "react-router-dom";
import Settings from "static/svgs/Settings";

interface MaintenanceModeProps {}

const MaintenanceMode: FC<MaintenanceModeProps> = () => {
  const [windowWidth, setWindowWidth] = useState<number | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== null && window.innerWidth !== undefined) {
        setWindowWidth(window?.innerWidth);
      }
    };

    // Initial width calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigateHome = () => {
    navigate("/");
  };
  return (
    <MaintenanceModeWrapper>
      <div className="inner-wrapper">
        <div className="robot-wrapper">
          <Settings />
          <h1>In Maintenance Mode</h1>
          <h3>The site is currently in maintenance mode, and should be back up shortly. Check back again soon.</h3>
          <GreenButton
            width={"fit-content"}
            height={`fit-content`}
            fontSize={25}
            paddingTop={15}
            paddingBottom={15}
            paddingLeft={40}
            paddingRight={40}
            style={{marginTop: "50px"}}
            onClick={() => handleNavigateHome()}
          >
            Go Back Home
          </GreenButton>
        </div>
      </div>
    </MaintenanceModeWrapper>
  );
};

export default MaintenanceMode;
