import React from "react"
import {
  Section,
  VerticalSection,
  MarketingGreenButtonWrapper,
} from "../MarketingHomepage.styled"
import HeroBottomLogos from "static/svgs/HeroBottomLogos"
import Dashboard from "static/images/dashboard.png"
import { HeroSectionWrapper } from "./HeroSection.styled"
import { useNavigate } from "react-router-dom"
import Sparkle from "static/svgs/Sparkle"
import { StructuredText } from "react-datocms"

interface DatoImage {
  url: string
}

interface HomePage {
  headerText?: any
  headerSubtext?: string
  headerButtonText?: string
  heroImage?: DatoImage
}

type Props = {
  data: HomePage
}

const HeroSection = (props: Props) => {
  const navigate = useNavigate()
  return (
    <HeroSectionWrapper>
      <div className="section-left">
        <div>
          {props.data.headerText && (
            <StructuredText data={props.data.headerText.value} />
          )}
          <h4>{props.data && props.data.headerSubtext}</h4>
          <MarketingGreenButtonWrapper onClick={() => navigate("/signup")}>
            {props.data && props.data.headerButtonText}
          </MarketingGreenButtonWrapper>
        </div>
        {/* <HeroBottomLogos /> */}
      </div>
      <div className="section-right">
        <div style={{ position: "relative", width: "inherit" }}>
          <div className="star-box">
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <div className="svg-wrapper">
                <Sparkle />
              </div>
              Generate a summary of this deal for my manager.
            </div>
          </div>
          <div className="star-box box2">
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <div className="svg-wrapper">
                <Sparkle />
              </div>
              What are some questions I can ask to better understand the
              prospect’s decision process?
            </div>
          </div>
          <img src={props.data.heroImage?.url} />
        </div>
      </div>
    </HeroSectionWrapper>
  )
}

export default HeroSection
