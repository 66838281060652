import React, { useRef, useState, useEffect } from "react"
import { PersonalizedSectionWrapper } from "./PersonalizedSection.styled"
import { MarketingGreenButtonWrapper } from "../MarketingHomepage.styled"
import MarketingSectionLabel from "../..//MarketingSectionLabel/MarketingSectionLabel"
import QuickView2 from "static/images/quick-view-2.png"
import Sparkle2 from "static/svgs/Sparkle2"
import CodeBlock from "static/svgs/CodeBlock"
import BarChart from "static/svgs/BarChart"
import { useNavigate } from "react-router-dom"
import { StructuredText } from "react-datocms"

interface DatoImage {
  url: string
}

interface Homepage {
  personalizedHeader?: any
  personalizedSubheader?: string
  personalizedButtonText?: string
  personalizedImage?: DatoImage
}

type Props = {
  data: Homepage
}

const PersonalizedSection = (props: Props) => {
  const descriptionWidthRef = useRef<HTMLLIElement>(null)
  const imageTopRef = useRef<HTMLLIElement>(null)
  const [descriptionWidth, setDescriptionWidth] = useState<number | null>(null)
  const [imageTop, setImageTop] = useState<number | null>(null)
  const [minImageLeft, setMinImageLeft] = useState<number | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      if (imageTopRef.current) {
        setMinImageLeft(imageTopRef.current.offsetWidth)
      }
    }

    // Initial width calculation
    handleResize()

    // Event listener for window resize
    window.addEventListener("resize", handleResize)

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <PersonalizedSectionWrapper>
      <div className="row">
        <div className="section-left">
          <MarketingSectionLabel text="PERSONALIZED FOR TEAM EXCELLENCE" />
          <div>
            {props.data.personalizedHeader && (
              <StructuredText data={props.data.personalizedHeader.value} />
            )}
            <h4>{props.data && props.data.personalizedSubheader}</h4>
            {/* <div className="image-wrapper">
              <img src={QuickView2} />
            </div> */}
          </div>
          <ul>
            <li>
              <Sparkle2 />
              Automated and accurate deal updates for every rep
            </li>
            <li>
              <CodeBlock /> Share winning strategies to accelerate team
              learnings
            </li>
            <li>
              <BarChart /> Understand highest performing coaching
              recommendations
            </li>
          </ul>
          <MarketingGreenButtonWrapper onClick={() => navigate("/signup")}>
            {props.data && props.data.personalizedButtonText}
          </MarketingGreenButtonWrapper>
        </div>
        <div className="section-right">
          <div className="image-wrapper">
            <img src={props?.data?.personalizedImage?.url} />
          </div>
        </div>
      </div>
    </PersonalizedSectionWrapper>
  )
}

export default PersonalizedSection
