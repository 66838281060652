import { FC, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, logout } from "auth/authActions";
import { getUser, verify } from "api/helpers";
import { GetUser } from "types";
import { setUserData } from "auth/authActions";
import { endLoading, startLoading } from "state/appActions";

interface OAuthProps {}

const OAuth: FC<OAuthProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let dispatch = useDispatch();

  useEffect(() => {
    const fire = async () => {
      let response = await verify();
      const status = response?.status;
      // User is already authed in and is just making a new integration connection
      if (status === 200) {
        dispatch(startLoading());
        const wasAlreadyLoggedIn = localStorage.getItem("authAccess");
        dispatch(login());
        let data: GetUser = await getUser();
        if (data?.user) {
          dispatch(endLoading());
          dispatch(setUserData(data.user));
          if (wasAlreadyLoggedIn) {
            navigate("/app/settings/integrations");
          } else {
            navigate("/app/deals");
          }
        } else {
          dispatch(logout());
          dispatch(endLoading());
          navigate("/login");
        }
      } else {
        dispatch(logout());
        dispatch(endLoading());
        navigate("/login");
      }
    };
    fire();
  }, [location.search]);

  return (
    <div style={{ height: "100vh" }}>

    </div>
  );
};

export default OAuth;
