import { colors } from "colors"
import styled from "styled-components"

export const FooterWrapper = styled.div`
  min-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  background-color: #000000;
  padding-top: 100px;
  padding-bottom: 100px;
  .footer-left {
    flex: 0 1 100%;
    padding-left: unset;
    display: flex;
    justify-content: center;
    div {
      width: fit-content;
      svg {
        margin-bottom: 10px;
      }
      p {
        margin: 0 0 10px 0;
        font-size: 12px;
      }
    }
  }
  .footer-right {
    height: 100%;
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    padding-right: 75px;
    margin-bottom: 50px;
    div {
      display: flex;
      div {
        display: flex;
        justify-content: center;
        flex: 0 1 33%;
        min-width: 90px;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            color: #98a2b3;
            margin-bottom: 10px;
            text-align: left;
          }
          .list-header {
            color: white;
          }
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    .footer-left {
      flex: 0 1 50%;
      padding-left: 75px;
      justify-content: unset;
    }
    .footer-right {
      margin-bottom: unset;
    }
  }
`
