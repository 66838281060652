import React from "react";

const Sparkle = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_1049_23254)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.05381 2.42152C6.3241 2.42152 6.56165 2.6007 6.6359 2.86059L7.29229 5.15797C7.5796 6.16353 8.36562 6.94955 9.37118 7.23686L11.6686 7.89325C11.9284 7.9675 12.1076 8.20504 12.1076 8.47534C12.1076 8.74563 11.9284 8.98317 11.6686 9.05742L9.37118 9.71382C8.36562 10.0011 7.5796 10.7871 7.29229 11.7927L6.6359 14.0901C6.56165 14.35 6.3241 14.5291 6.05381 14.5291C5.78352 14.5291 5.54598 14.35 5.47172 14.0901L4.81533 11.7927C4.52803 10.7871 3.742 10.0011 2.73644 9.71382L0.43907 9.05742C0.179179 8.98317 0 8.74563 0 8.47534C0 8.20504 0.179179 7.9675 0.43907 7.89325L2.73644 7.23686C3.74201 6.94955 4.52803 6.16353 4.81533 5.15797L5.47172 2.86059C5.54598 2.6007 5.78352 2.42152 6.05381 2.42152Z"
        fill="url(#paint0_linear_1049_23254)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3184 0C13.5962 0 13.8383 0.189059 13.9057 0.458555L14.1146 1.29435C14.3044 2.0535 14.8972 2.64624 15.6563 2.83603L16.4921 3.04498C16.7616 3.11235 16.9507 3.3545 16.9507 3.63229C16.9507 3.91008 16.7616 4.15222 16.4921 4.21959L15.6563 4.42854C14.8972 4.61833 14.3044 5.21107 14.1146 5.97022L13.9057 6.80602C13.8383 7.07551 13.5962 7.26457 13.3184 7.26457C13.0406 7.26457 12.7985 7.07551 12.7311 6.80602L12.5221 5.97022C12.3323 5.21107 11.7396 4.61833 10.9805 4.42854L10.1447 4.21959C9.87516 4.15222 9.6861 3.91008 9.6861 3.63229C9.6861 3.3545 9.87516 3.11235 10.1447 3.04498L10.9805 2.83603C11.7396 2.64624 12.3323 2.0535 12.5221 1.29435L12.7311 0.458555C12.7985 0.189059 13.0406 0 13.3184 0Z"
        fill="url(#paint1_linear_1049_23254)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1076 10.8969C12.3682 10.8969 12.5995 11.0636 12.6819 11.3108L13.0002 12.2655C13.1207 12.627 13.4044 12.9107 13.7659 13.0312L14.7206 13.3495C14.9678 13.4319 15.1345 13.6632 15.1345 13.9238C15.1345 14.1843 14.9678 14.4157 14.7206 14.4981L13.7659 14.8163C13.4044 14.9368 13.1207 15.2205 13.0002 15.5821L12.6819 16.5367C12.5995 16.7839 12.3682 16.9507 12.1076 16.9507C11.847 16.9507 11.6157 16.7839 11.5333 16.5367L11.2151 15.5821C11.0946 15.2205 10.8109 14.9368 10.4493 14.8163L9.49466 14.4981C9.24746 14.4157 9.08072 14.1843 9.08072 13.9238C9.08072 13.6632 9.24746 13.4319 9.49466 13.3495L10.4493 13.0312C10.8109 12.9107 11.0946 12.627 11.2151 12.2655L11.5333 11.3108C11.6157 11.0636 11.847 10.8969 12.1076 10.8969Z"
        fill="url(#paint2_linear_1049_23254)"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1049_23254"
        x="-36.783"
        y="-36.783"
        width="90.5166"
        height="90.5166"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="18.3915" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1049_23254"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1049_23254"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1049_23254"
        x1="8.47534"
        y1="0"
        x2="8.47534"
        y2="16.9507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7CDF4D" />
        <stop offset="1" stop-color="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1049_23254"
        x1="8.47534"
        y1="0"
        x2="8.47534"
        y2="16.9507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7CDF4D" />
        <stop offset="1" stop-color="#4CABCA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1049_23254"
        x1="8.47534"
        y1="0"
        x2="8.47534"
        y2="16.9507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7CDF4D" />
        <stop offset="1" stop-color="#4CABCA" />
      </linearGradient>
    </defs>
  </svg>
);

export default Sparkle;
