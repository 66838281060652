import { colors } from "colors";
import styled from "styled-components";
import {
  LoginInput,
  LoginLabel,
} from "styles/shared_styled_comps/forms.styled";

export const UploadFileWrapper = styled.div`
  color: white;
  padding: 40px 60px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${colors.black};
  height: 100%;
  border-radius: 10px;
  h2 {
    text-align: center;
    width: 100%;
  }
  height: 740px;
  position: relative;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
  width: 700px;
  .drag-and-drop {
    border: 5px dashed ${colors.deepGray};
    padding: 25px;
    height: 220px;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: ${colors.deepGray};
      font-size: 12px;
    }
  }
  .upload-button {
    background-color: ${colors.lightGray};
    color: ${colors.black};
    padding: 12px 6px;
    border-radius: 3px;
    width: 100px;
    height: 35px;
    border: none;
    font-weight: 700;
    cursor: pointer;
  }
  .drag-over {
    border: 3px solid ${colors.mainGreen};
  }
  .selected-tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    h4 {
      font-size: 16px;
      margin-left: 5px;
      font-weight: 300;
    }
    p {
      width: fit-content;
      height: 20px;
      font-size: 12px;
      background-color: ${colors.mainGreen};
      color: ${colors.white2};
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 5px;
      margin: 0 5px;
      border-radius: 3px;
      cursor: pointer;
      transition: transform 0.2s ease;
      &:hover {
        transform: scale(1.04);
      }
    }
    span {
      color: ${colors.deepGray};
      font-weight: 700;
      margin-left: 6px;
    }
  }
  .selected-file {
    p {
      color: ${colors.white};
      font-weight: 700;
      font-size: 20px;
    }
  }
  .available-tags {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    background-color: rgba(198, 200, 207, 0.1);
    border-radius: 5px;
    p {
      min-width: fit-content;
      height: 20px;
      font-size: 12px;
      background-color: ${colors.offWhite};
      color: ${colors.black};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 5px;
      margin: 5px;
      border-radius: 3px;
      cursor: pointer;
      transition: transform 0.2s ease;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
`;

export const Input = styled(LoginInput)`
  color: white;
  background: transparent;
  border: 1px solid white;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 40px;
`;

export const Label = styled(LoginLabel)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`;
