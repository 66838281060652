import React, { FC, useEffect, useState } from "react";
import { startLoading, endLoading } from "state/appActions";
import { ForgotPasswordWrapper } from "./ForgotPassword.styled";
import { postForgotPassword, deleteForgotPassword } from "api/helpers";
import {
  LoginInput,
  LoginLabel,
  SquareButton,
} from "styles/shared_styled_comps/forms.styled";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { MarketingPageWrapper } from "styles/shared_styled_comps/components.styled";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token") || "";

  const handleForgotPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!email) {
      setText("Enter a valid email address.");
      return;
    }
    postForgotPassword({ email });
    setText("Please check your email for a link to reset your password.");
  };

  const handleSubmitPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setError("");
    if (password.length < 8) {
      setError("Passwords must be atleast 8 characters.");
      return;
    }
    if (!password || !confirmPassword) {
      setError("Please fill out all fields.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    dispatch(startLoading());
    let response = await deleteForgotPassword({ password, magic_token: token });
    dispatch(endLoading());
    if (response?.status === 200) {
      setText("Your password has been changed successfully. Please login.");
    } else {
      setError("An error occured");
    }
    postForgotPassword({ email });
  };

  useEffect(() => {
    if (token) {
      setStep(2);
    }
  }, [token]);

  return (
    <MarketingPageWrapper>
      <ForgotPasswordWrapper>
        {step === 1 ? (
          <>
            <h2>Forgot password?</h2>
            <h4>
              Enter your email address and we will send you a link to reset your
              password.
            </h4>
            <h4>
              {" "}
              <strong>
                Please note we cannot assist if you have authenticated through a
                third party app.
              </strong>
            </h4>
            <form style={{ width: "100%" }} onSubmit={handleForgotPassword}>
              <LoginLabel>
                Email:
                <LoginInput
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </LoginLabel>
              <SquareButton type="submit">Submit</SquareButton>
              {text && <p className="">{text}</p>}
            </form>
          </>
        ) : (
          <>
            <h2>Enter your new password</h2>
            <form style={{ width: "100%" }} onSubmit={handleSubmitPassword}>
              <LoginLabel>
                New Password:
                <LoginInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </LoginLabel>
              <LoginLabel>
                Confirm New Password:
                <LoginInput
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </LoginLabel>
              <SquareButton type="submit">Submit</SquareButton>
              {error && <p className="error">{error}</p>}
              {text && <p className="">{text}</p>}
            </form>
          </>
        )}
      </ForgotPasswordWrapper>
    </MarketingPageWrapper>
  );
};

export default ForgotPassword;
