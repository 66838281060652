import React, { useState } from "react";
import { MarketingWaitlistPageWrapper } from "./MarketingWaitlistPage.styled";
import MarketingSectionLabel from "components/MarketingSectionLabel/MarketingSectionLabel";
import StepUpSection from "components/MarketingHomepage/StepUpSection/StepUpSection";
import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";
import { WaitlistFormWrapper } from "./MarketingWaitlistPage.styled";
import { useDispatch } from "react-redux";
import { postWaitlist } from "api/helpers";
import { startLoading, endLoading } from "state/appActions";
import {
  LoginInput,
  LoginLabel,
} from "styles/shared_styled_comps/forms.styled";

type Props = {};

const MarketingWaitlistPage = (props: Props) => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  let dispatch = useDispatch();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (email.length > 0 && email.includes("@") && fullName.length > 0) {
      setError("")
      setSuccess("")
      dispatch(startLoading())
      try {
        await postWaitlist({ name: fullName, email: email });
        setSuccess("You have been added to our waitlist! Stay tuned...")
      } catch {
        setError("An error occured signing you up for the waitlist. Please refresh and try again.")
      }
      dispatch(endLoading())
    } else {
      setError("Please validate your name and email are filled out correctly")
    }
  };
  return (
    <MarketingWaitlistPageWrapper>
      <div className="inner-wrapper">
        <h2>Join our waitlist!</h2>
        <h4>
          Submit your business email and we'll be in touch to get your account
          set up in the near term! For now, please send any other inquiries to
          team@leadoff.ai.
        </h4>
        <WaitlistFormWrapper>
          <form>
            <div className="form-inner">
              {error && <p className="error">{error}</p>}
              <div>
                <LoginLabel>Full Name</LoginLabel>
                <LoginInput
                  onChange={(e) => setFullName(e.target.value)}
                  type="text"
                  placeholder="John Doe"
                />
              </div>
              <div>
                <LoginLabel>Company Email</LoginLabel>
                <LoginInput
                  placeholder="johndoe@company.com"
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="flex">
              <MarketingGreenButtonWrapper
                onClick={(e) => handleSubmit(e)}
                width={150}
                type="submit"
              >
                Submit
              </MarketingGreenButtonWrapper>
            </div>
            {success && <p>{success}</p>}
          </form>
        </WaitlistFormWrapper>
      </div>
    </MarketingWaitlistPageWrapper>
  );
};

export default MarketingWaitlistPage;
