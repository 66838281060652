import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import Cookies from "js-cookie";
import { Route, Routes, useLoaderData, useNavigate } from "react-router-dom";
import MarketingHomepage from "components/MarketingHomepage/MarketingHomepage";
import MarketingTeamPage from "components/MarketingTeamPage/MarketingTeamPage";
import MarketingFAQsPage from "components/MarketingFAQsPage/MarketingFAQsPage";
import MarketingBlog from "components/MarketingBlog/MarketingBlog";
import MarketingBlogPost from "components/MarketingBlog/MarketingBlogPost";
import MarketingWaitlistPage from "components/MarketingWaitlistPage/MarketingWaitlistPage";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Navigation/Footer";
import ProtectedRoute from "auth/ProtectedRoute";
import { useDispatch } from "react-redux";
import Dashboard from "components/Dashboard/Dashboard";
import NotFound from "components/NotFound/NotFound";
import Login from "components/Login/Login";
import AboutUs from "components/AboutUs/AboutUs";
import DealPage from "components/DealPage/DealPage";
import AddDeal from "components/AddDeal/AddDeal";
import AddCall from "components/AddCall/AddCall";
import SideBar from "components/SideBar/SideBar";
import DealCoach from "components/DealCoach/DealCoach";
import Signup from "components/Signup/Signup";
import SettingsPage from "components/Settings/SettingsPage";
import DealsPage from "components/DealsPage/DealsPage";
import OAuth from "components/OAuth/OAuth";
import Loading from "components/Loading/Loading";
import Billing from "components/Settings/Billing";
import CoachingSettings from "components/Settings/Coaching";
import CompanySettings from "components/Settings/Company";
import Integrations from "components/Settings/Integrations";
import Profile from "components/Settings/Profile";
import Reports from "components/Settings/Reports";
import Resources from "components/Resources/Resources";
import ErrorModal from "components/ErrorModal/ErrorModal";
import SettingsNotifications from "components/Settings/SettingsNotifications";
import FourZeroFour from "components/404/FourZeroFour";
import Terms from "components/Terms/Terms";
import SLA from "components/SLA/SLA";
import DPA from "components/DPA/DPA";
import Privacy from "components/Privacy/Privacy";

import globalRouter from "./globalRouter";
import "./styles/App.css";
import { getUser } from "api/helpers";
import { GetUser } from "./types";
import { setUserData } from "auth/authActions";
import { useSelector } from "react-redux";
import { setDarkMode } from "state/appActions";
import { setupAxiosInstance } from "axiosConfig";
import { logout } from "auth/authActions";
import store from "store";
import { useLocation } from "react-router-dom";
import { initAmplitude } from "amplitude";
import Tracking from "Tracking";
import LeadoffTour from "components/LeadoffTour/LeadoffTour";
import { Position } from "@reactour/tour";
import initSentry from "./sentry";
import * as Sentry from "@sentry/react";
import AdminLogin from "components/AdminLogin/AdminLogin";
import Impersonate from "components/Impersonate/Impersonate";
import MaintenanceMode from "components/MaintenanceMode/MaintenanceMode";
import ForgotPassword from "components/ForgotPassword/ForgotPassword";
import ResetPassword from "components/ResetPassword/ResetPassword";

// Initialize Sentry
initSentry();

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  initAmplitude();
  let dispatch = useDispatch();
  setupAxiosInstance(store);
  const app_prefix = "app";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  globalRouter.navigate = navigate;

  const sidebarRef = useRef<HTMLDivElement>(null);

  const isLoading = useSelector((state: any) => state.app.isLoading);
  const error = useSelector((state: any) => state.app.error);
  const user = useSelector((state: any) => state.auth.user);

  // TOUR VARS
  const [tourIsOpen, setTourIsOpen] = useState<boolean>(
    user && !user?.completed_intro_tour
  );
  const [tourStep, setTourStep] = useState<number>(0);
  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(true);
  const [tourDisabledActions, setTourDisabledActions] =
    useState<boolean>(false);
  const [tourPosition, setTourPosition] = useState<Position>("right");
  const [endTour, setEndTour] = useState<boolean>(false);
  useEffect(() => {
    if (user && user?.completed_intro_tour !== undefined) {
      setTourIsOpen(user && !user?.completed_intro_tour);
    }
  }, [user]);
  console.log(tourPosition);
  useEffect(() => {
    const localDarkMode = localStorage.getItem("darkMode") === "true";
    // Dispatch action to set dark mode value in Redux state
    dispatch(setDarkMode(localDarkMode));
    if (localDarkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }

    // Load User Data
    if (localStorage.getItem("authAccess")) {
      const fetchUserData = async () => {
        let data: GetUser = await getUser();
        if (data && data.user) {
          dispatch(setUserData(data.user));
        } else {
          dispatch(logout());
          navigate("/login");
        }
      };
      fetchUserData();
    }
  }, [endTour]);

  const setAllowCookies = () => {
    Cookies.set("allowCookies", "true", { expires: 7 }); // Cookie expires in 7 days
  };

  useEffect(() => {
    const allowCookies = Cookies.get("allowCookies");
    if (allowCookies) {
      setShowCookieBanner(false);
    }
  }, []);

  const handleAllowCookies = () => {
    setAllowCookies();
    setShowCookieBanner(false);
  };

  return (
    <>
      <div className="App">
        {showCookieBanner && (
          <div className="cookie-banner">
            <p>
              We use <strong>cookies</strong> to ensure our website works properly, personalize
              your browsing experience, and analyze how you use our website.
              Learn more about how we use cookies by visiting our privacy policy
              page.
            </p>
            <p>
              The functionality of Leadoff.ai™ requires the tracking of certain
              cookies. For more information visit our <a href="https://leadoff.ai/privacy">privacy policy</a>.
            </p>
            <div className="cookie-button-wrapper">
              <button onClick={() => handleAllowCookies()}>Accept</button>
              <button onClick={() => handleAllowCookies()}>Reject</button>
            </div>
          </div>
        )}
        {pathname.includes(app_prefix) && !user.completed_intro_tour && (
          <LeadoffTour
            isOpen={tourIsOpen}
            setIsOpen={setTourIsOpen}
            currentStep={tourStep}
            setCurrentStep={setTourStep}
            disabledActions={tourDisabledActions}
            setDisabledActions={setTourDisabledActions}
            sidebarRef={sidebarRef}
            tourPosition={tourPosition}
            setTourPosition={setTourPosition}
            setEndTour={setEndTour}
          />
        )}

        <Tracking />
        {isLoading && <Loading />}
        {error && <ErrorModal />}
        <Navigation setTourStep={setTourStep} setTourIsOpen={setTourIsOpen} />
        <SideBar
          tourStep={tourStep}
          setTourStep={setTourStep}
          setTourIsOpen={setTourIsOpen}
          ref={sidebarRef}
        />
        <SentryRoutes>
          {/* Marketing routes */}
          <Route path="/" Component={MarketingHomepage} />
          <Route path="*" Component={FourZeroFour} />
          <Route path="/team" Component={MarketingTeamPage} />
          <Route path="/about" Component={AboutUs} />
          <Route path="/faqs" Component={MarketingFAQsPage} />
          <Route path="/waitlist" Component={MarketingWaitlistPage} />
          <Route path="/blog" Component={MarketingBlog} />
          <Route path="/blog/:postId" Component={MarketingBlogPost} />
          <Route path="/dealShare" Component={DealPage} />
          <Route path="/privacy" Component={Privacy} />
          <Route path="/terms" Component={Terms} />
          <Route path="/dpa" Component={DPA} />
          <Route path="/sla" Component={SLA} />
          <Route path="/salesrus" Component={AdminLogin} />
          <Route path="/impersonate" Component={Impersonate} />
          <Route path="/maintenance" Component={MaintenanceMode} />
          <Route path="/forgot-password" Component={ForgotPassword} />
          <Route path="/reset-password" Component={ResetPassword} />
          {/* Auth Routes */}
          <Route path="/login" Component={Login} />
          <Route path="/signup" Component={Signup} />
          <Route path="/oauth" Component={OAuth} />
          {/* Logged-in Routes */}
          <Route
            path={`/${app_prefix}/dashboard`}
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route
            path={`/${app_prefix}/deals`}
            element={
              <ProtectedRoute
                tourStep={tourStep}
                setTourStep={setTourStep}
                setTourIsOpen={setTourIsOpen}
                tourPosition={tourPosition}
                setTourPosition={setTourPosition}
                component={DealsPage}
              />
            }
          />
          <Route
            path={`/${app_prefix}/deals/:dealID`}
            element={
              <ProtectedRoute
                tourStep={tourStep}
                setTourStep={setTourStep}
                setTourIsOpen={setTourIsOpen}
                component={DealPage}
              />
            }
          />
          <Route
            path={`/${app_prefix}/dealCoach`}
            element={<ProtectedRoute component={DealCoach} />}
          />
          <Route
            path={`/${app_prefix}/addCall/:dealID`}
            element={<ProtectedRoute component={AddCall} />}
          />
          <Route
            path={`/${app_prefix}/addDeal`}
            element={<ProtectedRoute component={AddDeal} />}
          />
          <Route
            path={`/${app_prefix}/leadboard`}
            element={<ProtectedRoute component={SettingsPage} />}
          />{" "}
          <Route
            path={`/${app_prefix}/resources`}
            element={<ProtectedRoute component={Resources} />}
          />
          <Route
            path={`/${app_prefix}/settings`}
            element={<ProtectedRoute component={SettingsPage} />}
          >
            <Route
              path={`/${app_prefix}/settings/profile`}
              element={<ProtectedRoute component={Profile} />}
            />
            <Route
              path={`/${app_prefix}/settings/reports`}
              element={<ProtectedRoute component={Reports} />}
            />
            <Route
              path={`/${app_prefix}/settings/notifications`}
              element={<ProtectedRoute component={SettingsNotifications} />}
            />
            <Route
              path={`/${app_prefix}/settings/company-account`}
              element={
                <ProtectedRoute
                  setTourIsOpen={setTourIsOpen}
                  component={CompanySettings}
                />
              }
            />
            <Route
              path={`/${app_prefix}/settings/coaching`}
              element={<ProtectedRoute component={CoachingSettings} />}
            />
            <Route
              path={`/${app_prefix}/settings/integrations`}
              element={
                <ProtectedRoute
                  setTourIsOpen={setTourIsOpen}
                  component={Integrations}
                />
              }
            />
            <Route
              path={`/${app_prefix}/settings/billing`}
              element={<ProtectedRoute component={Billing} />}
            />
          </Route>
          {/* Admin Routes */}
          <Route
            path={`/${app_prefix}/admin`}
            element={<ProtectedRoute component={Dashboard} />}
          />
        </SentryRoutes>
        {!pathname.includes(app_prefix) && <Footer />}
      </div>
    </>
  );
}

export default App;
