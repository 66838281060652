import React from "react";

const LeadoffRobot = () => (
  <svg
    width="47"
    height="45"
    viewBox="0 0 47 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#7CDF4D" />
        <stop offset="100%" stop-color="#4CABCA" />
      </linearGradient>
    </defs>
    <path
      d="M42.338 8.88857H37.5448L39.8124 3.90525C39.9713 3.57917 40.0633 3.22457 40.0829 2.86237C40.1025 2.50017 40.0493 2.13772 39.9265 1.79641C39.8037 1.4551 39.6138 1.14184 39.368 0.875134C39.1221 0.608425 38.8253 0.393667 38.4951 0.243535C38.1649 0.0934038 37.808 0.0109417 37.4454 0.00101683C37.0828 -0.00890802 36.7219 0.0539056 36.384 0.185749C36.0461 0.317593 35.738 0.515795 35.478 0.768657C35.2179 1.02152 35.0111 1.32392 34.8699 1.658L31.5771 8.88857C31.3081 9.47238 31.1904 10.1145 31.2349 10.7557C31.2794 11.397 31.4845 12.0167 31.8315 12.5578C32.1785 13.0989 32.6561 13.5439 33.2203 13.8519C33.7845 14.1599 34.4172 14.3208 35.06 14.32H39.8532L37.5856 19.3033C37.4267 19.6294 37.3347 19.984 37.3151 20.3462C37.2955 20.7084 37.3487 21.0708 37.4715 21.4121C37.5943 21.7535 37.7842 22.0667 38.03 22.3334C38.2759 22.6001 38.5727 22.8149 38.9029 22.965C39.2331 23.1152 39.59 23.1976 39.9526 23.2075C40.3152 23.2175 40.6761 23.1547 41.014 23.0228C41.3519 22.891 41.66 22.6928 41.92 22.4399C42.1801 22.187 42.3869 21.8846 42.5281 21.5506L45.8209 14.32C46.0899 13.7362 46.2076 13.0941 46.1631 12.4528C46.1186 11.8116 45.9135 11.1918 45.5665 10.6507C45.2195 10.1097 44.7419 9.66461 44.1777 9.35665C43.6135 9.04869 42.9808 8.88772 42.338 8.88857Z"
      fill="#EEF7F2"
    />
    <path
      d="M10.8629 44.8717H29.8728C32.7538 44.8717 35.5168 43.7272 37.554 41.6901C39.5912 39.6529 40.7357 36.8899 40.7357 34.0089V30.6143C40.7357 29.894 40.4496 29.2033 39.9403 28.694C39.431 28.1847 38.7402 27.8985 38.02 27.8985C37.2997 27.8985 36.609 28.1847 36.0997 28.694C35.5904 29.2033 35.3043 29.894 35.3043 30.6143V34.0089C35.3043 35.4494 34.732 36.8309 33.7134 37.8495C32.6948 38.8681 31.3133 39.4403 29.8728 39.4403H10.8629C9.42239 39.4403 8.04089 38.8681 7.0223 37.8495C6.00371 36.8309 5.43148 35.4494 5.43148 34.0089V14.9989C5.43148 13.5584 6.00371 12.1769 7.0223 11.1583C8.04089 10.1398 9.42239 9.56752 10.8629 9.56752H23.0836C23.8038 9.56752 24.4946 9.2814 25.0039 8.77211C25.5132 8.26282 25.7993 7.57207 25.7993 6.85182C25.7993 6.13157 25.5132 5.44082 25.0039 4.93152C24.4946 4.42223 23.8038 4.13611 23.0836 4.13611H10.8629C7.98189 4.13611 5.21888 5.28058 3.18171 7.31776C1.14453 9.35493 6.10352e-05 12.1179 6.10352e-05 14.9989V34.0089C6.10352e-05 36.8899 1.14453 39.6529 3.18171 41.6901C5.21888 43.7272 7.98189 44.8717 10.8629 44.8717Z"
      fill="#EEF7F2"
    />
    <path
      d="M12.9 23.146C14.3998 23.146 15.6157 21.9302 15.6157 20.4303C15.6157 18.9305 14.3998 17.7146 12.9 17.7146C11.4001 17.7146 10.1843 18.9305 10.1843 20.4303C10.1843 21.9302 11.4001 23.146 12.9 23.146Z"
      fill="#EEF7F2"
    />
    <path
      d="M27.8361 23.146C29.336 23.146 30.5518 21.9302 30.5518 20.4303C30.5518 18.9305 29.336 17.7146 27.8361 17.7146C26.3363 17.7146 25.1204 18.9305 25.1204 20.4303C25.1204 21.9302 26.3363 23.146 27.8361 23.146Z"
      fill="#EEF7F2"
    />
    <path
      d="M21.2915 34.6131C22.3142 34.435 23.2645 33.9675 24.0298 33.266C24.795 32.5645 25.3432 31.6584 25.6094 30.655C25.8806 29.6696 25.857 28.6263 25.5415 27.6541C25.4332 27.3295 25.2253 27.0472 24.9474 26.8473C24.6696 26.6475 24.3359 26.5402 23.9936 26.5407H16.7427C16.3992 26.5387 16.064 26.6454 15.7848 26.8453C15.5056 27.0453 15.2967 27.3284 15.1879 27.6541C15.0209 28.1718 14.9361 28.7124 14.9367 29.2564C14.9361 30.0482 15.1086 30.8305 15.4421 31.5486C15.7756 32.2668 16.2621 32.9033 16.8675 33.4136C17.4729 33.9239 18.1826 34.2957 18.9467 34.5029C19.7109 34.7101 20.5112 34.7477 21.2915 34.6131Z"
      fill="#EEF7F2"
    />
  </svg>
);

export default LeadoffRobot;
