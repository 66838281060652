import React from "react";

const Cycle = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="vuesax/outline/convert-3d-cube">
      <g id="convert-3d-cube">
        <path
          id="Vector"
          d="M12.5008 19.4583C12.2758 19.4583 12.0675 19.3333 11.9591 19.1417C11.8508 18.95 11.8508 18.7083 11.9675 18.5167L12.8425 17.0583C13.0175 16.7583 13.4008 16.6667 13.7008 16.8417C14.0008 17.0167 14.0925 17.4 13.9175 17.7L13.6925 18.075C15.9925 17.5334 17.7175 15.4667 17.7175 13C17.7175 12.6583 18.0008 12.375 18.3425 12.375C18.6841 12.375 18.9675 12.6583 18.9675 13C18.9591 16.5583 16.0591 19.4583 12.5008 19.4583Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_2"
          d="M1.66797 8.62435C1.3263 8.62435 1.04297 8.34102 1.04297 7.99935C1.04297 4.44102 3.94297 1.54102 7.5013 1.54102C7.7263 1.54102 7.93463 1.66602 8.04296 1.85769C8.1513 2.04935 8.1513 2.29102 8.03463 2.48269L7.15963 3.94102C6.98463 4.24102 6.6013 4.33269 6.3013 4.15769C6.0013 3.98269 5.90964 3.59934 6.08464 3.29934L6.30964 2.92433C4.00964 3.46599 2.28464 5.53268 2.28464 7.99935C2.29297 8.34102 2.00964 8.62435 1.66797 8.62435Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_3"
          d="M14.733 6.74953C14.6246 6.74953 14.5163 6.72455 14.4163 6.66622L11.108 4.74951C10.808 4.57451 10.708 4.1912 10.883 3.8912C11.058 3.5912 11.4413 3.49119 11.733 3.66619L14.733 5.39953L17.708 3.67453C18.008 3.49953 18.3913 3.60788 18.558 3.89954C18.733 4.19954 18.6247 4.58285 18.333 4.75785L15.0413 6.65787C14.9497 6.71621 14.8413 6.74953 14.733 6.74953Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_4"
          d="M14.7344 10.1422C14.3927 10.1422 14.1094 9.85887 14.1094 9.5172V6.11719C14.1094 5.77552 14.3927 5.49219 14.7344 5.49219C15.076 5.49219 15.3594 5.77552 15.3594 6.11719V9.5172C15.3594 9.8672 15.076 10.1422 14.7344 10.1422Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_5"
          d="M14.7328 10.2923C14.3495 10.2923 13.9578 10.209 13.6495 10.034L11.6495 8.92563C10.9995 8.5673 10.5078 7.72567 10.5078 6.984V4.8673C10.5078 4.1173 10.9995 3.284 11.6578 2.91733L13.6578 1.80898C14.2745 1.46732 15.1995 1.46732 15.8245 1.80898L17.8245 2.91733C18.4745 3.27567 18.9661 4.1173 18.9661 4.85896V6.97566C18.9661 7.72566 18.4745 8.55896 17.8245 8.91729L15.8245 10.0256C15.4995 10.209 15.1161 10.2923 14.7328 10.2923ZM14.2578 2.89231L12.2578 4.00066C12.0078 4.14232 11.7578 4.56729 11.7578 4.85062V6.96732C11.7578 7.25899 12.0078 7.684 12.2578 7.81734L14.2578 8.93397C14.4995 9.06731 14.9661 9.06731 15.2078 8.93397L17.2078 7.82563C17.4578 7.68396 17.7078 7.25899 17.7078 6.97566V4.85896C17.7078 4.5673 17.4578 4.14233 17.2078 4.009L15.2078 2.90065C14.9661 2.75898 14.4911 2.75897 14.2578 2.89231Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_6"
          d="M5.26815 15.9175C5.15982 15.9175 5.05148 15.8925 4.95148 15.8342L1.64314 13.9175C1.34314 13.7425 1.24314 13.3592 1.41814 13.0592C1.59314 12.7592 1.97648 12.6592 2.26815 12.8342L5.26815 14.5675L8.24314 12.8425C8.54314 12.6675 8.92648 12.7758 9.09315 13.0675C9.26815 13.3675 9.15981 13.7508 8.86814 13.9258L5.57648 15.8258C5.48481 15.8842 5.37648 15.9175 5.26815 15.9175Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_7"
          d="M5.26562 19.3082C4.92396 19.3082 4.64062 19.0249 4.64062 18.6832V15.2832C4.64062 14.9415 4.92396 14.6582 5.26562 14.6582C5.60729 14.6582 5.89062 14.9415 5.89062 15.2832V18.6832C5.89062 19.0332 5.61562 19.3082 5.26562 19.3082Z"
          fill="#DEE2D4"
        />
        <path
          id="Vector_8"
          d="M5.26797 19.4578C4.88464 19.4578 4.49297 19.3745 4.18464 19.1995L2.18463 18.0911C1.53463 17.7328 1.04297 16.8912 1.04297 16.1495V14.0328C1.04297 13.2828 1.53463 12.4494 2.18463 12.0911L4.18464 10.9828C4.80131 10.6411 5.73463 10.6411 6.3513 10.9828L8.35131 12.0911C9.00131 12.4494 9.49297 13.2911 9.49297 14.0328V16.1495C9.49297 16.8995 9.0013 17.7328 8.34296 18.0995L6.34297 19.2078C6.04297 19.3745 5.65964 19.4578 5.26797 19.4578ZM4.79297 12.0578L2.79297 13.1661C2.54297 13.3078 2.29297 13.7328 2.29297 14.0161V16.1328C2.29297 16.4245 2.54297 16.8495 2.79297 16.9828L4.79297 18.0911C5.03464 18.2245 5.5013 18.2245 5.74297 18.0911L7.74296 16.9828C7.99296 16.8412 8.24297 16.4161 8.24297 16.1328V14.0161C8.24297 13.7244 7.99296 13.2995 7.74296 13.1661L5.74297 12.0495C5.50963 11.9245 5.03464 11.9245 4.79297 12.0578Z"
          fill="#DEE2D4"
        />
      </g>
    </g>
  </svg>
);

export default Cycle;
