import { FC } from "react";
import { SearchBarWrapper } from "./SearchBar.styled";
import Search from "static/svgs/Search";

interface SearchBarProps {
  updateFunction: Function;
  value: string;
}

const SearchBar: FC<SearchBarProps> = ({ value, updateFunction }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFunction(event.target.value);
  }
  return (
    <SearchBarWrapper>
      <div className="svg-wrapper">
        <Search />
      </div>
      <input value={value} onChange={(e) => handleChange(e)} className="search-input" placeholder="Search"></input>
    </SearchBarWrapper>
  );
};

export default SearchBar;
