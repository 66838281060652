import React, { FC } from "react";
import {
  ErrorModalWrapper,
  ErrorModalDiv,
  MatrixCode,
} from "./ErrorModal.styled";
import { useDispatch, useSelector } from "react-redux";
import { GreenButton } from "styles/shared_styled_comps/components.styled";
import { useNavigate } from "react-router-dom";
import { setError } from "state/appActions";

interface ErrorModalProps {}

const ErrorModal: FC<ErrorModalProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let error = useSelector((state: any) => state.app.error);
  const handleRefresh = () => {
    dispatch(setError(""));
    navigate("/app/deals");
  };
  // Don't display error message for users in prod
  if (process.env.REACT_APP_ENV === "production") {
    error = "An error occured. Please refresh and try again.";
  }

  return (
    <ErrorModalWrapper>
      <ErrorModalDiv>
        <h1>Oops, an error occurred</h1>
        <h4>{error}</h4>
        <GreenButton style={{ 'zIndex': '100000000' }} onClick={handleRefresh} fontSize={20} height={60} width={'150px'}>Reload</GreenButton>
        <MatrixCode>
          {`01101000 01100101 01101100 01101100 01101111 00101100 00100000 01110111 01101111 01110010 01101100 01100100 00100001 // Hello, world!
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
const pi = 3.14159;
let radius = 5;
let area = pi * radius * radius;
console.log("The area of the circle is: ", area); // Print the area of the circle
01100011 01101111 01100100 01100101 01100010 01101100 01101111 01100011 01101001 01110100 // codeblock
for (let i = 0; i < 10; i++) {
  console.log("Iteration ", i);
} // Iterate from 0 to 9
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110`}
        </MatrixCode>
        <MatrixCode style={{'top': '100%'}}>
          {`01101000 01100101 01101100 01101100 01101111 00101100 00100000 01110111 01101111 01110010 01101100 01100100 00100001 // Hello, world!
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 
00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101
 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 
 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
typedef struct{int id;float val;}Datum;
typedef struct{int length;Datum*data;}DataArray;
void init_data_array(DataArray*array){array->length=NUM_DATA_POINTS;array->data=(Datum*)malloc(NUM_DATA_POINTS*sizeof(Datum));
for(int i=0;i<NUM_DATA_POINTS;i++){array->data[i].id=i;array->data[i].val=(float)i*3.14;}}
void print_data_array(DataArray*array){printf("DataArrayLength:%d\n",array->length);for(int i=0;i<array->length;i++)
{Datum*datum=&(array->data[i]);printf("Datum%d-Identifier:%d,Value:%.2f\n",i,datum->id,datum->val);}}
int main(){DataArray data;init_data_array(&data);print_data_array(&data);free(data.data);return 0;}
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110`}
        </MatrixCode>
        <MatrixCode style={{'top': '100%'}}>
          {`01101000 01100101 01101100 01101100 01101111 00101100 00100000 01110111 01101111 01110010 01101100 01100100 00100001 // Hello, world!
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
class FlightControlSystem(list):
    def __init__(self, vector):
        super().__init__(vector)
 def perform_acceleration(self, other):
        return FlightControlSystem([x + y for x, y in zip(self, other)] def perform_guidance(self, other):  return FlightControlSystem([x * y for x, y in zip(self, other)])
    def perform_attitude_determination(self, other):
        return FlightControlSystem([x - y for x, y in zip(self, other)])
    def perform_stabilization(self, other):
        return FlightControlSystem([x / y for x, y in zip(self, other)])
    def __repr__(self):
        return f"FlightControlSystem({super().__repr__()})"
def encode_signal(signal: str):
    return ''.join([chr(ord(char) + 1) if char.isalpha() else char for char in signal])
def decode_signal(encoded_signal: str):
    return ''.join([chr(ord(char) - 1) if char.isalpha() else char for char in encoded_signal])
def execute_autopilot_sequence(phase1, phase2):
    return reduce(lambda x, y: FlightControlSystem(x) * FlightControlSystem(y), itertools.zip_longest(phase1, phase2, fillvalue=1))
if __name__ == "__main__":
    attitude_control = FlightControlSystem([1, 2, 3, 4, 5])
    thrust_vectoring = FlightControlSystem([5, 4, 3, 2, 1])
    navigation_vector = FlightControlSystem([10, 20, 30, 40, 50])
    propulsion_system = FlightControlSystem([100, 200, 300, 400, 500]) 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110
01110100 01101000 01101001 01110011 00100000 01101001 01110011 00100000 01100001 01101110 00100000 01100101 01111000 01100001 01101101 01110000 01101100 01100101 00100000 01101111 01100110 00100000 01100001 00100000 01101101 01101001 01111000 01100101 01100100 00100000 01110011 01110100 01110010 01101001 01101110 01100111 00101100 00100000 01100010 01110101 01110100 00100000 01101001 01110100 00100111 01110011 00100000 01110000 01110010 01100101 01110100 01100101 01101110 01100100 01100101 01100100 00101110`}
        </MatrixCode>
      </ErrorModalDiv>
    </ErrorModalWrapper>
  );
};
export default ErrorModal;
