import { colors } from "colors"
import styled from "styled-components"

interface ToolTipWrapperProps {
  pointSide: string
}

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
  width: 330px;
  min-height: 240px;
  height: fit-content;
  background-color: ${colors.white2};
  display: block;
  position: absolute;
  ${(props) => {
    switch (props.pointSide) {
      case "left":
        return `
        top: 22px;
        left: 170%;
        .triangle {
          top: 6px;
          left: 2%;
        }
        `
      case "right":
        return `
        top: 47px;
        left: 27%;
        `
      case "top":
        return `
          top: 47px;
          left: 27%;
        `
      case "top-right":
        return `
          top: 47px;
          right: -18px;
          transform: unset !important;
          .triangle {
            right: 0;
          }
        `
      case "bottom":
        return `
        top: 47px;
        left: 27%;
        `
      default:
        return ""
    }
  }}
  transform: translate(-50%, 0);
  z-index: 100;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 80px;
  color: ${colors.black};
  p {
    width: 100%;
    text-align: left;
    font-weight: 400;
  }
  .triangle {
    height: 38px;
    width: 20px;
    position: absolute;
    background: ${colors.white2};
    transform: translate(-50%, 0) rotate(45deg);
  }
  button {
    width: 90%;
    height: 60px;
    background-color: ${colors.black};
    border: none;
  }
`
