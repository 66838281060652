// reducers.js
import { START_LOADING, END_LOADING } from "./appActions";

const initialState = {
  isLoading: false,
  loadingText: "",
  loadingSubText: "",
  darkMode: true,
  error: "",
  pageTitle: "",
  showCallModal: false,
  showAddDealModal: false,
  stages: [
    "Discovery",
    "Demonstrating Value",
    "Proposal",
    "Business Case",
    "Selected",
    "Contracting",
    "Closed Won",
    "Closed Lost",
  ],
};

const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false, loadingText: "", loadingSubText: "" };
    case "SET_DARK_MODE":
      return {
        ...state,
        darkMode: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.value,
      };
    case "SET_PAGE_TITLE":
      return {
        ...state,
        pageTitle: action.value,
      };
    case "SET_LOADING_TEXT":
      return {
        ...state,
        loadingText: action.value,
      };
    case "SET_LOADING_SUB_TEXT":
      return {
        ...state,
        loadingSubText: action.value,
      };
    case "SET_CALL_MODAL":
      return {
        ...state,
        showCallModal: action.value,
      };
    case "SET_ADD_DEAL_MODAL":
      // Stop background scrolling when modal is open
      if (action.value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      return {
        ...state,
        showAddDealModal: action.value,
      };
    default:
      return state;
  }
};

export default appReducer;
