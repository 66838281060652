import React from "react"

const AuthSparkle = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1059_152044)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.2707 6.9083C18.0418 6.9083 18.7195 7.41947 18.9314 8.16091L20.804 14.715C21.6236 17.5838 23.866 19.8262 26.7348 20.6458L33.2889 22.5184C34.0303 22.7303 34.5415 23.4079 34.5415 24.179C34.5415 24.9501 34.0303 25.6278 33.2889 25.8397L26.7348 27.7123C23.866 28.5319 21.6236 30.7743 20.804 33.6431L18.9314 40.1972C18.7195 40.9386 18.0418 41.4498 17.2707 41.4498C16.4996 41.4498 15.822 40.9386 15.6101 40.1972L13.7375 33.6431C12.9179 30.7743 10.6755 28.5319 7.80671 27.7123L1.25261 25.8397C0.511175 25.6278 0 24.9501 0 24.179C0 23.4079 0.511175 22.7303 1.25261 22.5184L7.80672 20.6458C10.6755 19.8262 12.9179 17.5837 13.7375 14.715L15.6101 8.16091C15.822 7.41947 16.4996 6.9083 17.2707 6.9083Z"
          fill="#EEF7F2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.9956 0C38.7881 0 39.4789 0.539361 39.6711 1.3082L40.2672 3.69262C40.8087 5.85837 42.4997 7.54939 44.6654 8.09083L47.0499 8.68694C47.8187 8.87915 48.3581 9.56995 48.3581 10.3624C48.3581 11.1549 47.8187 11.8457 47.0499 12.038L44.6654 12.6341C42.4997 13.1755 40.8087 14.8665 40.2672 17.0323L39.6711 19.4167C39.4789 20.1855 38.7881 20.7249 37.9956 20.7249C37.2031 20.7249 36.5123 20.1855 36.3201 19.4167L35.724 17.0323C35.1826 14.8665 33.4916 13.1755 31.3258 12.6341L28.9414 12.038C28.1725 11.8457 27.6332 11.1549 27.6332 10.3624C27.6332 9.56995 28.1725 8.87915 28.9414 8.68694L31.3258 8.09083C33.4916 7.54939 35.1826 5.85837 35.724 3.69262L36.3201 1.3082C36.5123 0.539361 37.2031 0 37.9956 0Z"
          fill="#EEF7F2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.5415 31.0873C35.2849 31.0873 35.9448 31.563 36.1799 32.2683L37.0878 34.9918C37.4316 36.0232 38.241 36.8326 39.2724 37.1764L41.9959 38.0843C42.7012 38.3193 43.1769 38.9793 43.1769 39.7227C43.1769 40.4661 42.7012 41.1261 41.9959 41.3612L39.2724 42.269C38.241 42.6128 37.4316 43.4222 37.0878 44.4536L36.1799 47.1771C35.9448 47.8824 35.2849 48.3581 34.5415 48.3581C33.7981 48.3581 33.1381 47.8824 32.903 47.1771L31.9952 44.4536C31.6514 43.4222 30.842 42.6128 29.8106 42.269L27.087 41.3612C26.3818 41.1261 25.9061 40.4661 25.9061 39.7227C25.9061 38.9793 26.3818 38.3193 27.087 38.0843L29.8106 37.1764C30.842 36.8326 31.6514 36.0232 31.9952 34.9918L32.903 32.2683C33.1381 31.563 33.7981 31.0873 34.5415 31.0873Z"
          fill="#EEF7F2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1059_152044"
          x="-135.403"
          y="-135.403"
          width="319.164"
          height="319.163"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="67.7013" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1059_152044"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1059_152044"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default AuthSparkle
