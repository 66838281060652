import { FC } from "react";
import {
  CompanyBreakdownWrapper,
  DealCoachCard,
  ContactCard,
} from "./CompanyBreakdown.styled";
import Cycle from "static/svgs/Cycle";
import Sparkle from "static/svgs/Sparkle";
import { Company } from "types";

interface CompanyBreakdownProps {
  company?: Company | null;
  handleMessageSend: Function;
}

const CompanyBreakdown: FC<CompanyBreakdownProps> = ({ company, handleMessageSend }) => {
  if (company) {
    return (
      <CompanyBreakdownWrapper>
        <h1>{company?.buyer_company}</h1>
        <div>
        <p className="summary">
          {company?.about_company}
        </p>
        {company.products && <p><strong>Products:</strong> {company.products}</p>}
        {company.team_specifics && <p><strong>Team:</strong> {company.team_specifics}</p>}
        </div>
        <div className="regen-wrapper">
          <p>Pull Date: {company?.timestamp}</p>
          <button>
            <Cycle />
            Regenerate
          </button>
        </div>
        <div className="regen-wrapper">
          <DealCoachCard onClick={() => handleMessageSend(`Guide me on how to align our solution to meet ${company.buyer_company}'s goals.`)}>
            <div className="flex">
              <Sparkle />
              <p>ASK YOUR DEAL COACH!</p>
            </div>
            <p>Guide me on how to align our solution to meet {company?.buyer_company}'s goals.</p>
          </DealCoachCard>
          <DealCoachCard inverse onClick={() => handleMessageSend('')}>
            <div className="flex">
              <Sparkle />
              <p>GET HELP WITH SOMETHING ELSE?</p>
            </div>
            <p>Take me to my deal coach</p>
          </DealCoachCard>
        </div>
        <h1>Company Details</h1>
        <div className="bottom-wrapper">
          {company?.employees?.length > 0 && <ContactCard>
            <h3>{company?.employees[0].name}</h3>
            {company?.employees[0].title && <p>{company?.employees[0].title}</p>}
            <p className="website">{company?.employees[0].position}</p>
          </ContactCard>}
          <div className="info">
            <p>
              <strong>Industry: </strong>{company.industry}
            </p>
            {company.company_headcount && <p>
              <strong>Size: </strong>{company.company_headcount}
            </p>}
            {company.company_revenue && <p>
              <strong>Revenue: </strong>{company.company_revenue}
            </p>}
            {company?.competitors && <p>
              <strong>Primary Competitors: </strong>{company?.competitors}
            </p>}
            {/* <p>
              <strong>Recent Achievement: </strong>Launched substitute teaching
              opportunities in Illinois, specifically in Chicago and Oak Lawn,
              with pay rates ranging from $145-$238 per day.
            </p> */}
          </div>
        </div>
      </CompanyBreakdownWrapper>
    );
  } else {
    return <></>
  }
};

export default CompanyBreakdown;
