import React from "react"

const Stars = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_1049_18344)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.2149 7.33758C17.9695 7.33758 18.6326 7.83779 18.8399 8.56333L20.6724 14.9769C21.4745 17.7842 23.6688 19.9785 26.476 20.7806L32.8896 22.613C33.6152 22.8203 34.1154 23.4835 34.1154 24.238C34.1154 24.9926 33.6152 25.6558 32.8896 25.8631L26.476 27.6955C23.6688 28.4976 21.4745 30.6919 20.6724 33.4992L18.8399 39.9127C18.6326 40.6383 17.9695 41.1385 17.2149 41.1385C16.4603 41.1385 15.7972 40.6383 15.5899 39.9127L13.7574 33.4991C12.9554 30.6919 10.761 28.4976 7.95379 27.6955L1.54021 25.8631C0.814668 25.6558 0.314453 24.9926 0.314453 24.238C0.314453 23.4835 0.814668 22.8203 1.54021 22.613L7.9538 20.7806C10.761 19.9785 12.9554 17.7841 13.7574 14.9769L15.5899 8.56333C15.7972 7.83779 16.4603 7.33758 17.2149 7.33758Z"
          fill="url(#paint0_linear_1049_18344)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M37.4955 0.577393C38.271 0.577393 38.947 1.10519 39.135 1.85754L39.7184 4.19085C40.2482 6.31016 41.903 7.96493 44.0223 8.49476L46.3556 9.07808C47.1079 9.26617 47.6357 9.94216 47.6357 10.7177C47.6357 11.4932 47.1079 12.1692 46.3556 12.3573L44.0223 12.9406C41.903 13.4704 40.2482 15.1252 39.7184 17.2445L39.135 19.5778C38.947 20.3301 38.271 20.8579 37.4955 20.8579C36.72 20.8579 36.044 20.3301 35.8559 19.5778L35.2725 17.2445C34.7427 15.1252 33.088 13.4704 30.9686 12.9406L28.6353 12.3573C27.883 12.1692 27.3552 11.4932 27.3552 10.7177C27.3552 9.94216 27.883 9.26617 28.6353 9.07808L30.9686 8.49476C33.088 7.96493 34.7427 6.31016 35.2725 4.19085L35.8559 1.85754C36.044 1.10519 36.72 0.577393 37.4955 0.577393Z"
          fill="url(#paint1_linear_1049_18344)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34.1154 30.9982C34.8428 30.9982 35.4886 31.4637 35.7187 32.1538L36.6071 34.819C36.9435 35.8283 37.7355 36.6203 38.7448 36.9567L41.41 37.8451C42.1001 38.0752 42.5656 38.721 42.5656 39.4484C42.5656 40.1759 42.1001 40.8217 41.41 41.0518L38.7448 41.9402C37.7355 42.2766 36.9435 43.0686 36.6071 44.0779L35.7187 46.7431C35.4886 47.4332 34.8428 47.8987 34.1154 47.8987C33.3879 47.8987 32.7421 47.4332 32.5121 46.7431L31.6237 44.0779C31.2872 43.0686 30.4952 42.2766 29.4859 41.9402L26.8207 41.0518C26.1306 40.8217 25.6651 40.1759 25.6651 39.4484C25.6651 38.721 26.1306 38.0752 26.8207 37.8451L29.4859 36.9567C30.4952 36.6203 31.2872 35.8283 31.6237 34.819L32.5121 32.1538C32.7421 31.4637 33.3879 30.9982 34.1154 30.9982Z"
          fill="url(#paint2_linear_1049_18344)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1049_18344"
          x="-132.185"
          y="-131.922"
          width="312.319"
          height="312.32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="66.2498" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1049_18344"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1049_18344"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1049_18344"
          x1="23.9751"
          y1="0.577393"
          x2="23.9751"
          y2="47.8987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1049_18344"
          x1="23.9751"
          y1="0.577393"
          x2="23.9751"
          y2="47.8987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1049_18344"
          x1="23.9751"
          y1="0.577393"
          x2="23.9751"
          y2="47.8987"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Stars
