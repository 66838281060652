import styled from "styled-components";
import { colors } from "colors";

export const MaintenanceModeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  height: 120vh;

  width: 100vw;
  .inner-wrapper {
    max-width: 1800px;
    .robot-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        margin-bottom: 30px;
        width: 200px;
        height: 200px;
        padding-top: 100px;
      }
      h1 {
        color: ${colors.mainGreen};
        margin-bottom: 30px;
        text-align: center;
      }
      h3 {
        padding: 0 50px;
        text-align: center;
      }
    }
  }

  @media (min-width: 516px) {
    height: calc(100vh - 374px);
  }
`;
