import { FC, useEffect, useState, useRef } from "react"
import {
  RightWrapper,
  LeftWrapper,
  ResourcesWrapper,
  File,
  FullFile,
} from "./Resources.styled"
import PageWrapper from "components/reusable/PageWrapper/PageWrapper"
import { getOrgResources, deleteOrgResource, getOrgResource } from "api/helpers"
import { FloatingWrapper } from "styles/shared_styled_comps/components.styled"
import SearchBar from "components/reusable/SearchBar/SearchBar"
import { useDispatch, useSelector } from "react-redux"
import { startLoading, endLoading } from "state/appActions"
import UploadFile from "components/reusable/UploadFile/UploadFile"
import { ModalWrapper } from "styles/shared_styled_comps/components.styled"
import Documents from "static/svgs/Documents"
import { Resource } from "types"
import Trash from "static/svgs/Trash"

const default_org_resources = ["MEDDPICC Methodology", "BANT Methodology", "MEDDIC Methodology"]

interface ResourcesProps {}

const Resources: FC<ResourcesProps> = () => {
  const [resources, setResources] = useState([])
  const [search, setSearch] = useState("")
  const [upload, setUpload] = useState(false)
  const user = useSelector((state: any) => state.auth.user);
  let dispatch = useDispatch()
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setUpload(false)
    }
  }

  const deleteResource = async (resource_id: string) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this resource? It will be deleted permanently from the system and no longer will be used to assist Leadoff in making recommendations."
    )
    if (userConfirmed) {
      dispatch(startLoading())
      let response = await deleteOrgResource(resource_id)
      dispatch(endLoading())
      window.location.reload()
    } else {
      return
    }
  }

  const getIndividualResource = async (resource_id: string) => {
    let org_response = await getOrgResource(resource_id)
  }

  useEffect(() => {
    const getResources = async () => {
      dispatch(startLoading())
      let response = await getOrgResources()
      setResources(response.org_resources)
      dispatch(endLoading())
    }
    getResources()
  }, [])

  return (
    <PageWrapper pageTitle="Resources">
      <FloatingWrapper width={"1200px"} height={"790px"}>
        <ResourcesWrapper>
          <LeftWrapper>
            {resources.length > 0 ? (
              <>
                <div className="search-bar-wrapper">
                  <SearchBar
                    value={search}
                    updateFunction={setSearch}
                  ></SearchBar>
                </div>
                <div className="resources">
                  <h5>Shared Files</h5>
                  <div className="resource-scroll">
                    {resources.map((resource: Resource, index: number) => (
                      <File>
                        <div className="icon-wrapper">
                          <Documents />
                        </div>
                        <div className="info-wrapper">
                          <h3>
                            {resource.resource_name}.{resource.file_extension}
                          </h3>
                          <p className="extension">
                            {resource.file_extension.toUpperCase()}
                          </p>
                          <p className="small">{resource.timestamp}</p>
                          <p className="small">{resource.uploader_string}</p>
                        </div>
                      </File>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>No files available</h2>
                <p>Upload a file to get started</p>
              </>
            )}
          </LeftWrapper>
          <RightWrapper>
            <div className="button-wrapper">
              <button onClick={() => setUpload(true)}>Upload</button>
            </div>
            <div className="resources">
              <h5>Top Shared Resources</h5>
              <div className="resource-inner">
                {resources
                  .slice(0, 4)
                  .map((resource: Resource, index: number) => (
                    <FullFile
                      onClick={() =>
                        getIndividualResource(resource.org_resource_id)
                      }
                    >
                      <div className="gray">
                        {!default_org_resources.includes(resource.resource_name) && user.user_role === "admin" && <div
                          onClick={() =>
                            deleteResource(resource.org_resource_id)
                          }
                        >
                          <Trash />
                        </div>}
                      </div>
                      <div className="bottom">
                        <div className="icon-wrapper">
                          <Documents />
                        </div>
                        <div className="info-wrapper">
                          <h3>
                            {resource.resource_name}.{resource.file_extension}
                          </h3>
                          <p>{resource.description.length > 80 ? resource.description.substring(0, 80) + '...' : resource.description}</p>
                          <p className="small">{resource.timestamp}</p>
                          <p className="small">{resource.uploader_string}</p>
                        </div>
                      </div>
                    </FullFile>
                  ))}
              </div>
            </div>
          </RightWrapper>
        </ResourcesWrapper>
      </FloatingWrapper>
      {upload && (
        <ModalWrapper onClick={(e) => handleClickOutside(e)}>
          <div ref={modalRef}>
            <UploadFile setModal={setUpload} />
          </div>
        </ModalWrapper>
      )}
    </PageWrapper>
  )
}

export default Resources
