import styled from "styled-components";
import { colors } from "colors";

export const MarketingHomepageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.black};

  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 175px 25px 0 25px;

    @media (max-width: 906px) {
      padding-top: 10px;
    }

    h1 {
      font-size: 68px;
      font-weight: 700;
    }
    h2 {
      font-size: 35px;
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
    }
  }

  @media (min-width: 694px) {
    .inner-wrapper {
      h2 {
        font-size: 53px;
      }
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 190px 78px 0 78px;
    }
  }
`;

// COMPONENTS
interface SectionProps {
  marginBottom?: number;
  marginTop?: number;
  alignItems?: string;
  justifyContent?: string;
}

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props?.marginBottom && `${props.marginBottom}px`};
  margin-top: ${(props) => props?.marginTop && `${props.marginTop}px`};
  align-items: ${(props) => props?.alignItems && `${props.alignItems}`};
  justify-content: ${(props) =>
    props?.justifyContent && `${props.justifyContent}`};
  position: relative;
  h1 {
    margin-bottom: 14px;
  }
  h3 {
    margin-bottom: 25px;
  }
`;

interface VerticalSectionProps {
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingRight?: number;
  paddingLeft?: number;
  justify?: string;
  align?: string;
  flex?: string;
}
export const VerticalSection = styled.div<VerticalSectionProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex && props?.flex ? props.flex : `0 1 50%`)};
  align-items: ${(props) => props?.align && `${props.align}`};
  justify-content: ${(props) => props?.justify && `${props.justify}`};
  margin-bottom: ${(props) => props?.marginBottom && `${props.marginBottom}px`};
  margin-top: ${(props) => props?.marginTop && `${props.marginTop}px`};
  margin-right: ${(props) => props?.marginRight && `${props.marginRight}px`};
  margin-left: ${(props) => props?.marginLeft && `${props.marginLeft}px`};
  padding-bottom: ${(props) =>
    props?.paddingBottom && `${props.paddingBottom}px`};
  padding-top: ${(props) => props?.paddingTop && `${props.paddingTop}px`};
  padding-right: ${(props) => props?.paddingRight && `${props.paddingRight}px`};
  padding-left: ${(props) => props?.paddingLeft && `${props.paddingLeft}px`};
  .white-border-button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    width: 135px;
    height: 47px;
    border-radius: 5px;
  }
`;

interface MarketingGreenButtonWrapperProps {
  width?: number;
}

export const MarketingGreenButtonWrapper = styled.button<MarketingGreenButtonWrapperProps>`
  width: ${(props) =>
    props.width && props?.width ? `${props.width}px` : `${393}px`};
  height: 55px;
  /* width: 393px; */
  font-size: 18px;
  background-color: ${colors.green};
  color: ${colors.white};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
  font-weight: 600;
  transition: color 0.3s ease, background-color 0.3s ease;
  &:hover {
    color: ${colors.green};
    background-color: ${colors.white};
  }
`;
