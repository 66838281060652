import { colors } from "colors";
import styled from "styled-components";

export const NotificationsWrapper = styled.div`
  position: relative;
  z-index: 100;
  .notification-icon {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    border: 1px solid #707277;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .alert {
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    top: 0;
    right: 0;
  }
  .notifications-dropdown {
    position: absolute;
    width: 300px;
    height: 400px;
    border-radius: 2px;
    top: 45px;
    left: -41px;
    height: fit-content;
    overflow-y: auto;
    background-color: ${colors.offWhite};
    max-height: 300px;
    box-shadow: 0px 9px 20px 0px #0000001a, 0px 35px 35px 0px #00000017,
      0px 80px 48px 0px #0000000d, 0px 142px 57px 0px #00000003,
      0px 222px 62px 0px #00000000;
  }
  h3 {
    margin-left: 10px;
    margin-top: 8px;
    color: ${colors.black};
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

interface NotificationProps {
  read: boolean;
}

export const NotificationWrapper = styled.div<NotificationProps>`
  height: fit-content;
  min-height: 60px;
  width: 100%;
  color: ${colors.black};
  cursor: pointer;
  padding: 10px;
  border-top: 1px solid ${colors.middleGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  svg {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    margin-right: 10px;
    path {
      fill: ${colors.mainGreen}
    }
  }
  &:hover {
    background-color: ${colors.fadedGreen};
  }
  ${(props) =>
    props.read
      ? `
    background-color: ${colors.offWhite};
  `
      : `
    background-color: ${colors.fadedGreen};
  `}
`;
