import styled from "styled-components"
import { LoginLabel, LoginInput } from "styles/shared_styled_comps/forms.styled"
import { colors } from "colors"

export const AddDealWrapper = styled.div`
  color: white;
  padding: 30px 60px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${colors.black};
  height: 100%;
  border-radius: 10px;
  h2 {
    text-align: center;
    width: 100%;
  }
  height: 750px;
  position: relative;
  width: 50%;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
  width: 700px;
  max-height: 100vh;
  overflow: auto;
  .svg-wrapper {
    position: absolute;
    top: 15px;
    left: 10px;
    transform: rotate(90deg);
    cursor: pointer;
  }
  .error {
    text-align: center;
    margin: 0;
  }
  .drag-and-drop {
    border: 5px dashed ${colors.deepGray};
    padding: 25px;
    height: 220px;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: ${colors.deepGray};
      font-size: 12px;
    }
  }
  .upload-button {
    background-color: ${colors.lightGray};
    color: ${colors.black};
    padding: 12px 6px;
    border-radius: 3px;
    width: 100px;
    height: 35px;
    border: none;
    font-weight: 700;
    cursor: pointer;
  }
  .drag-over {
    border: 3px solid ${colors.mainGreen};
  }
  .selected-file {
    p {
      color: ${colors.white};
      font-weight: 700;
      font-size: 20px;
    }
  }
  .switch-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 12px;
      cursor: pointer;
      text-decoration: underline;
      color: ${colors.lightGray};
    }
  }
`

export const AddDealForm = styled.form`
  width: 100%;
  select {
    box-shadow: none;
    height: 40px;
    margin-top: 5px;
    border: 1px solid white;
    background-color: ${colors.black};
    padding-right: 10px;
    max-width: 250px;
    width: 100%;
  }
  input {
    height: 40px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  label {
    margin-top: 10px;
  }
  .dual-input-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .small-input {
    width: 200px;
  }
`

export const InputWrapper = styled.div``

export const Input = styled(LoginInput)`
  color: white;
  background: transparent;
  border: 1px solid white;
  margin-top: 5px;
  margin-bottom: 20px;
`

export const CalendarInput = styled.input`
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  /* Add a calendar icon */
  background-repeat: no-repeat;
  background-position: right center;
  /* Adjust padding to accommodate the icon */
  width: 100%;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  /* Add hover effect */
  transition: border-color 0.3s ease-in-out;
  &:hover {
    border-color: #666;
  }
  /* Add focus effect */
  &:focus {
    border-color: #007bff;
  }
`

export const TranscriptInput = styled.textarea`
  width: 100%;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  height: 150px;
  margin-bottom: 50px;
`

export const Label = styled(LoginLabel)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
`

export const DealOrCall = styled.div`
  height: 450px;
  width: 700px;
  background-color: ${colors.black};
  margin: 0 auto;
  border-radius: 5px;
  padding: 80px;
  position: relative;
  h2 {
    text-align: center;
  }
`
