import {
  FC,
  useEffect,
  forwardRef,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import globalRouter from "globalRouter";
import {
  ChatWrapper,
  ChatBox,
  ChatBotMessage,
  UserMessage,
  TextInput,
  PrePromptsWrapper,
  DealCoachChatWrapper,
} from "./DealCoachChat.styled";
import { loadDealCoachMessages } from "api/helpers";
import { useSelector } from "react-redux";
import { getDealCoachPrePrompts, postDealCoachStatic } from "api/helpers";
import Send from "static/svgs/Send";
import { DropDown } from "styles/shared_styled_comps/forms.styled";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import Sparkle from "static/svgs/Sparkle";
import TypingIndicator from "static/svgs/TypingIndicator";
import { useTransition, useSpring, animated } from "react-spring";
import { easings } from "@react-spring/web";
import { GradientText } from "styles/shared_styled_comps/components.styled";
import { QuickDeal, Preprompt, Chat } from "types";

interface DealCoachChatProps {
  deal_id: string;
  height?: string;
  width?: string;
  deals?: QuickDeal[];
}

const DealCoachChat = forwardRef((props: DealCoachChatProps, ref) => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [currentDealId, setCurrentDealId] = useState(props.deal_id);
  const scrollRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: any) => state.auth.user);
  const [preprompts, setPreprompts] = useState<Preprompt[]>([]);

  useImperativeHandle(ref, () => ({
    async send(message: string) {
      sendMessage(undefined, message);
    },
  }));

  const scrollToBottomOfChat = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  };

  const scrollToTopOfChat = () => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      scrollElement.scrollTop = 0;
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const sendStaticMessage = async (prepromt_index?: number) => {
    let message = inputMessage;
    if (prepromt_index || prepromt_index === 0) {
      message = preprompts[prepromt_index].frontend_text;
    }
    setLoading(true);
    // Add Message to chat
    let newChats = [...chats, { content: message, role: "user" }];
    setChats(newChats);
    setInputMessage("");
    setTimeout(() => scrollToBottomOfChat(), 300);
    // Send to backend
    let bodyData = {
      prompt: message,
      deal_id: currentDealId,
      preprompt_category: "",
      _id: "",
    };
    if (prepromt_index || prepromt_index === 0) {
      bodyData._id = preprompts[prepromt_index]._id;
      let newpreprompts = [...preprompts];
      newpreprompts.splice(prepromt_index, 1);
      setPreprompts(newpreprompts);
    }
    // Create the new coaching chat with a loading icon
    newChats = [...newChats, { content: "loading", role: "assistant" }];
    // Pull the current chat index (edge case for no chats)
    let currentChatIndexValue = Math.max(0, newChats.length - 1);
    // Update the state with the new array
    setChats(newChats);

    let newChats2 = [...newChats];
    // Update the value at the specified index
    let chatToUpdate = newChats2[currentChatIndexValue];

    let response = await postDealCoachStatic(bodyData);

    if (chatToUpdate.content === "loading") {
      chatToUpdate.content = "";
    }

    if (response.message.match(/\\u[\dA-F]{4}/gi)) {
      response.message = JSON.parse(
        '"' + response.message.replace(/"/g, '\\"') + '"'
      );
    }

    chatToUpdate.content = response.message;
    newChats[currentChatIndexValue] = chatToUpdate;
    // Set the state with the modified array
    setChats(newChats2);
    //  Scroll To bottom of div
    setTimeout(() => scrollToBottomOfChat(), 300);

    setLoading(false);
  };

  const sendMessage = async (prepromt_index?: number, messagesend?: string) => {
    let message = inputMessage;
    if (messagesend) {
      message = messagesend;
    }
    if (prepromt_index || prepromt_index === 0) {
      message = preprompts[prepromt_index].frontend_text;
    }
    setLoading(true);
    // Add Message to chat
    let newChats = [...chats, { content: message, role: "user" }];
    setChats(newChats);
    setInputMessage("");
    setTimeout(() => scrollToBottomOfChat(), 300);
    // Send to backend
    let bodyData = {
      prompt: message,
      deal_id: currentDealId,
      preprompt_category: "",
      _id: "",
    };
    if (prepromt_index || prepromt_index === 0) {
      bodyData._id = preprompts[prepromt_index]._id;
      preprompts.splice(prepromt_index, 1);
    }
    // Create the new coaching chat with a loading icon
    newChats = [...newChats, { content: "loading", role: "assistant" }];
    // Pull the current chat index (edge case for no chats)
    let currentChatIndexValue = Math.max(0, newChats.length - 1);
    // Update the state with the new array
    setChats(newChats);
    fetch(`${process.env.REACT_APP_BASE_URL}/dealCoachStream`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userToken");
          if (globalRouter.navigate)
            globalRouter.navigate("/login?sessiontimeout=true");
          return;
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        scrollToBottomOfChat();
        const reader = response?.body?.getReader();

        function updateBotMessage(
          currentChats: Chat[],
          text: string,
          currentChatIndexValue: number
        ) {
          // Parse and unescape Unicode characters for emojis, only if it's an emoji
          if (text.match(/\\u[\dA-F]{4}/gi)) {
            text = JSON.parse('"' + text.replace(/"/g, '\\"') + '"');
          }
          const newChats = [...currentChats];
          // Update the value at the specified index
          let chatToUpdate = newChats[currentChatIndexValue];
          if (chatToUpdate.content === "loading") {
            chatToUpdate.content = "";
          }
          chatToUpdate.content = chatToUpdate.content + text;
          newChats[currentChatIndexValue] = chatToUpdate;
          // Set the state with the modified array
          setChats(newChats);
          //  Scroll To bottom of div
          scrollToBottomOfChat();
          return newChats;
        }

        let lastIncompleteWord: string | undefined = "";
        return new ReadableStream({
          start(controller) {
            function push() {
              reader?.read().then(({ done, value }) => {
                if (done) {
                  if (lastIncompleteWord) {
                    newChats = updateBotMessage(
                      newChats,
                      lastIncompleteWord + " ",
                      currentChatIndexValue
                    );
                  }
                  scrollToBottomOfChat();
                  controller.close();
                  return;
                }
                let textChunk = new TextDecoder("utf-8")
                  .decode(value)
                  .replace(/"/g, "");
                // textChunk = textChunk.replace('\n', '<br>'); // Replace newline characters with spaces
                let words = (lastIncompleteWord + textChunk).split(/\s+/);
                lastIncompleteWord = words.pop(); // Save the last word (may be incomplete)
                words.forEach((word) => {
                  if (word) {
                    // Check if word is not empty
                    // word = markdownParser(word);
                    // replace ##### from the word if needed:
                    // word = word.replace(/^#+\s*/, '');
                    // replace \ with "
                    word = word.replace(/\\/g, '"');

                    newChats = updateBotMessage(
                      newChats,
                      word + " ",
                      currentChatIndexValue
                    ); // Add each word to the chat bubble
                  }
                });
                controller.enqueue(value);
                push();
              });
            }
            push();
          },
        });
      })
      .catch((err) => console.error("Error in streaming response:", err));
    setLoading(false);
  };

  const loadOlderConversations = async () => {
    setLoading(true);
    const chatsResponse = await loadDealCoachMessages(
      currentDealId,
      pageNumber + 1,
      10
    );
    setPageNumber(pageNumber + 1);
    const messages = chatsResponse?.conversation;
    messages.sort((a: Chat, b: Chat) => {
      if (!a.timestamp) return -1; // Treat undefined timestamp as oldest
      if (!b.timestamp) return 1;
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
    if (messages?.length === 0) {
      setNoMoreMessages(true);
    } else {
      setChats((prevChats) => [...messages, ...prevChats]);
    }
    setLoading(false);
    scrollToTopOfChat();
  };

  const loadConversations = async () => {
    setLoading(true);
    const chatsResponse = await loadDealCoachMessages(currentDealId, 0, 10);
    const messages = chatsResponse?.conversation;
    messages.sort((a: Chat, b: Chat) => {
      if (!a.timestamp) return -1; // Treat undefined timestamp as oldest
      if (!b.timestamp) return 1;
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
    if (messages?.length > 0) {
      messages.push({
        content: "How can I help you today?",
        role: "assistant",
      });
    }
    setChats(messages);
    setLoading(false);
    scrollToBottomOfChat();
  };

  useEffect(() => {
    const loadConversationsWrapper = async () => {
      await loadConversations();
    };
    const fetchPrePromptsData = async () => {
      let data = await getDealCoachPrePrompts(
        currentDealId ? currentDealId : "all"
      );
      setPreprompts(data ? data.prompts : []);
    };
    const promiseAll = async () => {
      await Promise.all([fetchPrePromptsData(), loadConversationsWrapper()]);
      setTimeout(() => scrollToBottomOfChat(), 700);
    };
    promiseAll();
  }, [currentDealId]);

  useEffect(() => {
    setCurrentDealId(props.deal_id);
    scrollToBottomOfChat();
  }, [props.deal_id]);

  const transitions = useTransition(preprompts, {
    config: { easing: easings.easeOutQuad },
    from: { transform: "translateX(0%) scale(1)" },
    // enter: { transform: "translateX(100%) scale(0.5)" },
    leave: { transform: "translateX(-100%) scale(0.1)" },
  });

  return (
    <DealCoachChatWrapper height={props.height} width={props.width}>
      <ChatWrapper className="chat-box">
        <ChatBox ref={scrollRef}>
          {loading && <div className="spinner"></div>}
          {!currentDealId ? (
            <div className="no-messages">
              <div className="svg-wrapper">
                <LeadoffRobot />
              </div>
              <h3>Choose a deal to get started</h3>
              <DropDown
                value={currentDealId}
                onChange={(e) =>
                  e.target.value !== "" && setCurrentDealId(e.target.value)
                }
              >
                <option value="" disabled selected>
                  Select Deal
                </option>
                {props.deals &&
                  props.deals.map((deal: QuickDeal, index: number) => (
                    <option key={index} value={deal.id}>
                      {deal.name}
                    </option>
                  ))}
              </DropDown>
            </div>
          ) : (
            <>
              {chats.length > 0 ? (
                <>
                  {noMoreMessages ? <p className="no-more">No previous messages</p> : <p className="load-more" onClick={loadOlderConversations}>Load more messages</p>}
                  {chats.map((chat: Chat, index: number) => {
                    if (chat.role === "assistant") {
                      if (chat.content !== "loading") {
                        return (
                          <ChatBotMessage key={index}>
                            <div className="icon-wrapper">
                              <div className="icon">
                                <LeadoffRobot />
                              </div>
                              <div>
                                <p>COACH</p>
                                <div
                                  className="text"
                                  dangerouslySetInnerHTML={{
                                    __html: chat.content,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </ChatBotMessage>
                        );
                      } else {
                        return (
                          <ChatBotMessage key={index}>
                            <div className="icon-wrapper">
                              <div className="icon">
                                <LeadoffRobot />
                              </div>
                              <div>
                                <p>COACH</p>
                                <div className="inner-svg">
                                  <TypingIndicator />
                                </div>
                              </div>
                            </div>
                          </ChatBotMessage>
                        );
                      }
                    }
                    return (
                      <UserMessage key={index}>
                        <div className="pfp">
                          {user.image_url ? (
                            <img src={user.image_url} />
                          ) : (
                            user.first_name && (
                              <div className="letter-wrapper">
                                {user.first_name[0]}
                              </div>
                            )
                          )}
                        </div>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: chat.content,
                          }}
                        ></div>
                      </UserMessage>
                    );
                  })}
                </>
              ) : (
                <div className="no-messages">
                  <div className="svg-wrapper">
                    <LeadoffRobot />
                  </div>
                  <h3>How can I help you today?</h3>
                  <GradientText>
                    Choose a prompt or type a message to get started
                  </GradientText>
                </div>
              )}
            </>
          )}
        </ChatBox>
        <TextInput>
          <input
            onChange={(event) => setInputMessage(event.target.value)}
            value={inputMessage}
            placeholder="What can I help you with?"
            onKeyDown={handleKeyPress}
          />
          <button onClick={() => sendMessage()}>
            <Send />
          </button>
        </TextInput>
      </ChatWrapper>
      <PrePromptsWrapper>
        <h3>Get help from your deal coach</h3>
        <h5>Here are some ideas to help you get started</h5>
        <div className="preprompts-scroll">
          {transitions((style, preprompt: Preprompt, _, index: number) => (
            <animated.div
              style={style}
              key={index}
              id={`box-${index}`}
              className="preprompt"
            >
              <div className="inner-prompt" onClick={() => sendMessage(index)}>
                {!currentDealId && <div className="overlay"></div>}
                {/* <div className="prompt-title-wrapper">
                <Sparkle />
                <h3>GUIDE ME ON</h3>
              </div> */}
                <p>{preprompt.frontend_text}</p>
                <div className="shape"></div>
              </div>
            </animated.div>
          ))}
        </div>
      </PrePromptsWrapper>
    </DealCoachChatWrapper>
  );
});

export default DealCoachChat;
