import React from "react"

const People = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8062 15.7924C35.7471 15.7924 35.7076 15.7924 35.6485 15.7924H35.5499C31.8232 15.6741 29.043 12.7953 29.043 9.24602C29.043 5.61792 32.0007 2.67993 35.6091 2.67993C39.2175 2.67993 42.1752 5.63763 42.1752 9.24602C42.1554 12.815 39.3752 15.6938 35.826 15.8121C35.826 15.7924 35.826 15.7924 35.8062 15.7924ZM35.6091 5.61792C33.6175 5.61792 32.0007 7.2348 32.0007 9.22632C32.0007 11.1784 33.519 12.7558 35.471 12.8347C35.4908 12.815 35.6485 12.815 35.826 12.8347C37.7386 12.7361 39.2175 11.1587 39.2372 9.22632C39.2372 7.2348 37.6203 5.61792 35.6091 5.61792Z"
        fill="url(#paint0_linear_1049_18360)"
      />
      <path
        d="M35.827 30.3245C35.058 30.3245 34.289 30.2653 33.52 30.1273C32.7116 29.9893 32.1792 29.2203 32.3172 28.4118C32.4553 27.6034 33.2243 27.071 34.0327 27.209C36.458 27.6231 39.0214 27.1696 40.7368 26.026C41.6636 25.4147 42.1565 24.6457 42.1565 23.8767C42.1565 23.1077 41.6439 22.3584 40.7368 21.7471C39.0214 20.6035 36.4186 20.15 33.9736 20.5838C33.1651 20.7415 32.3961 20.1894 32.2581 19.381C32.1201 18.5726 32.6525 17.8036 33.4609 17.6655C36.6749 17.0937 40.0073 17.705 42.3734 19.2824C44.1086 20.4458 45.1142 22.1021 45.1142 23.8767C45.1142 25.6316 44.1283 27.3076 42.3734 28.4907C40.5791 29.6738 38.2524 30.3245 35.827 30.3245Z"
        fill="url(#paint1_linear_1049_18360)"
      />
      <path
        d="M12.0859 15.7923C12.0662 15.7923 12.0465 15.7923 12.0465 15.7923C8.49722 15.674 5.71698 12.7952 5.69727 9.24596C5.69727 5.61785 8.65497 2.66016 12.2634 2.66016C15.8718 2.66016 18.8294 5.61786 18.8294 9.22625C18.8294 12.7952 16.0492 15.674 12.5 15.7923L12.0859 14.3135L12.2239 15.7923C12.1845 15.7923 12.1253 15.7923 12.0859 15.7923ZM12.2831 12.8346C12.4014 12.8346 12.5 12.8346 12.6183 12.8544C14.3732 12.7755 15.9112 11.198 15.9112 9.24596C15.9112 7.25444 14.2943 5.63756 12.3028 5.63756C10.3113 5.63756 8.6944 7.25444 8.6944 9.24596C8.6944 11.1783 10.193 12.736 12.1056 12.8544C12.1253 12.8346 12.2042 12.8346 12.2831 12.8346Z"
        fill="url(#paint2_linear_1049_18360)"
      />
      <path
        d="M12.0665 30.3245C9.64115 30.3245 7.31443 29.6738 5.5201 28.4907C3.78491 27.3274 2.7793 25.6513 2.7793 23.8767C2.7793 22.1218 3.78491 20.4458 5.5201 19.2824C7.88625 17.705 11.2186 17.0937 14.4326 17.6655C15.2411 17.8036 15.7735 18.5726 15.6354 19.381C15.4974 20.1894 14.7284 20.7415 13.92 20.5838C11.4749 20.15 8.89188 20.6035 7.15669 21.7471C6.22995 22.3584 5.73699 23.1077 5.73699 23.8767C5.73699 24.6457 6.24967 25.4147 7.15669 26.026C8.87216 27.1696 11.4355 27.6231 13.8608 27.209C14.6692 27.071 15.4382 27.6231 15.5763 28.4118C15.7143 29.2203 15.1819 29.9893 14.3735 30.1273C13.6045 30.2653 12.8355 30.3245 12.0665 30.3245Z"
        fill="url(#paint3_linear_1049_18360)"
      />
      <path
        d="M23.9762 30.5217C23.917 30.5217 23.8776 30.5217 23.8184 30.5217H23.7198C19.9931 30.4034 17.2129 27.5245 17.2129 23.9753C17.2129 20.3472 20.1706 17.4092 23.779 17.4092C27.3874 17.4092 30.3451 20.3669 30.3451 23.9753C30.3254 27.5442 27.5451 30.4231 23.9959 30.5414C23.9959 30.5216 23.9959 30.5217 23.9762 30.5217ZM23.779 20.3472C21.7875 20.3472 20.1706 21.964 20.1706 23.9556C20.1706 25.9076 21.6889 27.4851 23.641 27.564C23.6607 27.5442 23.8184 27.5442 23.9959 27.564C25.9085 27.4654 27.3874 25.8879 27.4071 23.9556C27.4071 21.9838 25.7902 20.3472 23.779 20.3472Z"
        fill="url(#paint4_linear_1049_18360)"
      />
      <path
        d="M23.9749 45.0733C21.6087 45.0733 19.2425 44.4621 17.4088 43.2198C15.6736 42.0565 14.668 40.4002 14.668 38.6255C14.668 36.8706 15.6539 35.1749 17.4088 34.0115C21.096 31.5665 26.8734 31.5665 30.541 34.0115C32.2761 35.1749 33.2818 36.8312 33.2818 38.6058C33.2818 40.3607 32.2959 42.0565 30.541 43.2198C28.7072 44.4423 26.341 45.0733 23.9749 45.0733ZM19.0454 36.496C18.1186 37.1073 17.6257 37.8762 17.6257 38.6452C17.6257 39.4142 18.1383 40.1635 19.0454 40.7748C21.7073 42.5691 26.2227 42.5691 28.8846 40.7748C29.8114 40.1635 30.3043 39.3945 30.3043 38.6255C30.3043 37.8565 29.7917 37.1073 28.8846 36.496C26.2424 34.7017 21.727 34.7214 19.0454 36.496Z"
        fill="url(#paint5_linear_1049_18360)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1049_18360"
          x1="35.6091"
          y1="2.67993"
          x2="35.6091"
          y2="15.8121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1049_18360"
          x1="38.6753"
          y1="17.4551"
          x2="38.6753"
          y2="30.3245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1049_18360"
          x1="12.2634"
          y1="2.66016"
          x2="12.2634"
          y2="15.7923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1049_18360"
          x1="9.21824"
          y1="17.4551"
          x2="9.21824"
          y2="30.3245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1049_18360"
          x1="23.779"
          y1="17.4092"
          x2="23.779"
          y2="30.5414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1049_18360"
          x1="23.9749"
          y1="32.1777"
          x2="23.9749"
          y2="45.0733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default People
