import React, { FC, useState } from "react";
import { UploadFileWrapper, Input, Label } from "./UploadFile.styled";
import {
  GradientButton,
  GradientText,
} from "styles/shared_styled_comps/components.styled";
import { postOrgResource } from "api/helpers";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "state/appActions";

interface UploadFileProps {
  setModal: Function;
}

const tags = [
  "one-pager",
  "admin doc",
  "sales strategy",
  "marketing",
  "product",
  "finance",
  "metrics",
  "value proposition",
];

const UploadFile: FC<UploadFileProps> = ({ setModal }) => {
  let dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
    const selectedFile = event.dataTransfer.files?.[0];
    if (selectedFile) {
      if (selectedFile) {
        const fileNameParts = selectedFile.name.split(".");
        const fileType =
          fileNameParts.length > 1 ? fileNameParts.pop()?.toLowerCase() : "";
        console.log(fileType);
        if (
          fileType === "pdf" ||
          fileType === "docx" ||
          fileType === "doc" ||
          fileType === "txt"
        ) {
          setFile(selectedFile);
        } else {
          alert("Please select a PDF, DOCX, DOC, or TXT file.");
        }
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split(".");
      const fileType =
        fileNameParts.length > 1 ? fileNameParts.pop()?.toLowerCase() : "";
      console.log(fileType);
      if (
        fileType === "pdf" ||
        fileType === "docx" ||
        fileType === "doc" ||
        fileType === "txt"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a PDF, DOCX, DOC, or TXT file.");
      }
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file && name && description) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("tags", selectedTags.join(","));

      // You can now send the formData object to your server using fetch or any other method
      dispatch(startLoading());
      let response = await postOrgResource(formData);
      dispatch(endLoading());
      if (response.status == "success") {
        // Reset form fields after submission
        setFile(null);
        setName("");
        setDescription("");
        setSelectedTags([]);
        window.location.reload();
      } else {
        alert(
          "An error occured trying to upload your resource. Please refresh the page and try again"
        );
      }
    } else {
      alert("Please fill in all fields.");
    }
  };

  const selectTags = (index: number) => {
    // Remove tag from tags list and add to selected tags
    let selectedTag = tags.splice(index, 1)[0];
    const newArray: string[] = [...selectedTags, selectedTag];
    setSelectedTags(newArray);
  };

  const removeTag = (index: number) => {
    // Remove tag from tags list and add to selected tags
    const newArray: string[] = [...selectedTags];
    let selectedTag = newArray.splice(index, 1)[0];
    setSelectedTags(newArray);
    tags.push(selectedTag);
  };

  return (
    <UploadFileWrapper>
      <form onSubmit={handleSubmit}>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          className={`drag-and-drop ${isDraggingOver ? "drag-over" : ""}`}
        >
          Drag or drop a new file
          <p>(pdf, doc, docx, txt files supported)</p>
          {file && (
            <div className="selected-file">
              <p>Selected File: {file.name}</p>
            </div>
          )}
          <div>
            <input
              onChange={handleFileChange}
              type="file"
              id="selectedFile"
              accept=".pdf,.docx,.doc,.txt"
              style={{ display: "none" }}
            />
            <input
              className="upload-button"
              type="button"
              value="Upload"
              onClick={() => document?.getElementById("selectedFile")?.click()}
            />
          </div>
        </div>
        <div>
          <Label htmlFor="name">Document Name:</Label>
          <Input
            type="text"
            id="name"
            value={name}
            placeholder="Sales Methodology Framework"
            onChange={handleNameChange}
          />
        </div>
        <div>
          <Label htmlFor="description">Description:</Label>
          <Input
            id="description"
            value={description}
            placeholder="This document highlights the main sales strategies for our company."
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="tags-wrapper">
          <div className="selected-tags">
            Tags:
            {selectedTags.length > 0 ? (
              selectedTags.map((tag: string, index: number) => (
                <p onClick={() => removeTag(index)}>
                  {tag} <span>X</span>
                </p>
              ))
            ) : (
              <h4>Select up to three tags</h4>
            )}
          </div>
          <div className="available-tags">
            {tags.map((tag: string, index: number) => (
              <p
                onClick={
                  selectedTags.length < 3 ? () => selectTags(index) : undefined
                }
              >
                {tag}
              </p>
            ))}
          </div>
        </div>
        <GradientButton
          width="80%"
          height="60px"
          fontSize={14}
          top={30}
          type="submit"
        >
          <GradientText>Upload</GradientText>
        </GradientButton>
      </form>
    </UploadFileWrapper>
  );
};

export default UploadFile;
