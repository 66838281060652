import React from "react"

const TrendUp = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3821 30.2651C15.0075 30.2651 14.6328 30.1271 14.3371 29.8313C13.7652 29.2595 13.7652 28.3131 14.3371 27.7412L20.6468 21.4315C20.9623 21.116 21.3764 20.9582 21.8299 20.9977C22.2637 21.0371 22.6581 21.2737 22.9144 21.6484L25.0637 24.8821L32.0635 17.8822C32.6354 17.3104 33.5818 17.3104 34.1536 17.8822C34.7255 18.4541 34.7255 19.4005 34.1536 19.9723L25.8721 28.2539C25.5566 28.5694 25.1425 28.7271 24.689 28.6877C24.2552 28.6483 23.8609 28.4116 23.6045 28.037L21.4553 24.8032L16.4272 29.8313C16.1314 30.1271 15.7568 30.2651 15.3821 30.2651Z"
        fill="url(#paint0_linear_1049_18367)"
      />
      <path
        d="M33.1275 24.3498C32.3191 24.3498 31.6487 23.6794 31.6487 22.8709V20.4062H29.1839C28.3755 20.4062 27.7051 19.7358 27.7051 18.9273C27.7051 18.1189 28.3755 17.4485 29.1839 17.4485H33.1275C33.936 17.4485 34.6064 18.1189 34.6064 18.9273V22.8709C34.6064 23.6794 33.936 24.3498 33.1275 24.3498Z"
        fill="url(#paint1_linear_1049_18367)"
      />
      <path
        d="M30.1708 45.0538H18.34C7.63317 45.0538 3.05859 40.4793 3.05859 29.7724V17.9416C3.05859 7.23473 7.63317 2.66016 18.34 2.66016H30.1708C40.8777 2.66016 45.4523 7.23473 45.4523 17.9416V29.7724C45.4523 40.4793 40.8777 45.0538 30.1708 45.0538ZM18.34 5.61785C9.25004 5.61785 6.01629 8.8516 6.01629 17.9416V29.7724C6.01629 38.8624 9.25004 42.0961 18.34 42.0961H30.1708C39.2608 42.0961 42.4946 38.8624 42.4946 29.7724V17.9416C42.4946 8.8516 39.2608 5.61785 30.1708 5.61785H18.34Z"
        fill="url(#paint2_linear_1049_18367)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1049_18367"
          x1="24.2454"
          y1="17.4534"
          x2="24.2454"
          y2="30.2651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1049_18367"
          x1="31.1557"
          y1="17.4485"
          x2="31.1557"
          y2="24.3498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1049_18367"
          x1="24.2554"
          y1="2.66016"
          x2="24.2554"
          y2="45.0538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default TrendUp
