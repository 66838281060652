import React from "react"

const LinkedIn = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.4"
        cx="20.4995"
        cy="20.3628"
        r="20.019"
        fill="url(#paint0_linear_1398_168751)"
      />
      <g clip-path="url(#clip0_1398_168751)">
        <rect
          x="8.5"
          y="8.34375"
          width="24"
          height="24"
          rx="5"
          fill="url(#paint1_linear_1398_168751)"
        />
        <path
          d="M15.4395 13.3428C15.4392 13.8732 15.2282 14.3818 14.853 14.7567C14.4777 15.1316 13.9689 15.3421 13.4385 15.3418C12.908 15.3415 12.3994 15.1306 12.0245 14.7553C11.6496 14.38 11.4392 13.8712 11.4395 13.3408C11.4397 12.8104 11.6507 12.3018 12.0259 11.9269C12.4012 11.552 12.91 11.3415 13.4405 11.3418C13.9709 11.3421 14.4795 11.553 14.8544 11.9283C15.2293 12.3036 15.4397 12.8124 15.4395 13.3428ZM15.4995 16.8228H11.4995V29.3428H15.4995V16.8228ZM21.8195 16.8228H17.8395V29.3428H21.7795V22.7728C21.7795 19.1128 26.5495 18.7728 26.5495 22.7728V29.3428H30.4995V21.4128C30.4995 15.2428 23.4395 15.4728 21.7795 18.5028L21.8195 16.8228Z"
          fill="#292525"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1398_168751"
          x1="20.4995"
          y1="0.34375"
          x2="20.4995"
          y2="40.3818"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1398_168751"
          x1="20.5"
          y1="8.34375"
          x2="20.5"
          y2="32.3438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7CDF4D" />
          <stop offset="1" stop-color="#4CABCA" />
        </linearGradient>
        <clipPath id="clip0_1398_168751">
          <rect
            x="8.5"
            y="8.34375"
            width="24"
            height="24"
            rx="5"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedIn
