import {
  FC,
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import {
  getOrganization,
  getTeams,
  postRegistrationUser,
  deleteRegistrationUser,
  postTeam,
  putSpecificUser,
  deleteTeam,
} from "api/helpers";
import {
  OrganizationWrapper,
  UserRowWrapper,
  UserTableWrapper,
  InviteModal,
  TeamsWrapper,
} from "./Settings.styled";
import UserTable from "components/UserTable/UserTable";
import { ModalWrapper } from "styles/shared_styled_comps/components.styled";
import { DropDown } from "styles/shared_styled_comps/forms.styled";
import {
  InputWrapper,
  Input,
  Label,
  TranscriptInput,
} from "../AddDeal/AddDeal.styled";
import { Organization, Team, User } from "types";
import Trash from "static/svgs/Trash";
import { useLocation } from "react-router-dom";

interface CompanySettingsProps {
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CompanySettings: FC<CompanySettingsProps> = ({ setTourIsOpen }) => {
  let dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const [companyData, setCompanyData] = useState<Organization>();
  const [teamsData, setTeamsData] = useState<Team[]>();
  const [userData, setUserData] = useState<User[]>([]);
  const [inviteModal, setInviteModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState("");
  const [teamName, setTeamName] = useState("");
  const [role, setRole] = useState("User");
  const [message, setMessage] = useState("");
  const [showTeams, setShowTeams] = useState(false);
  const [success, setSuccess] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const invite = query.get("invite");

  useEffect(() => {
    if (user && !user?.completed_intro_tour && setTourIsOpen !== undefined) {
      setTourIsOpen(true);
    }
    // Auto open invite modal from refer a teammate button
    if (invite === "true") {
      setInviteModal(true)
    }
  }, []);

  const handleDeleteTeam = async (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    teamId: string
  ) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this team? It will be deleted permanently and all users on that team will no longer have a team assigned."
    );
    if (userConfirmed) {
      dispatch(startLoading());
      let response = await deleteTeam({ team_id: teamId });
      dispatch(endLoading());
      window.location.reload();
    } else {
      return;
    }
  };

  const handleInvite = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    let data = {
      message,
      email,
      role,
    };
    let response = await postRegistrationUser(data);
    setSuccess(true)
    dispatch(endLoading());
    window.location.reload();
  };

  const handleAddTeam = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(startLoading());
    let data = {
      team_name: teamName,
    };
    let response = await postTeam(data);
    dispatch(endLoading());
    window.location.reload();
  };

  const deleteRegUser = async (email: string) => {
    dispatch(startLoading());
    let data = {
      email,
      remove: true,
    };
    let response = await deleteRegistrationUser(data);
    if (response) {
      window.location.reload();
    }
    dispatch(endLoading());
  };

  useEffect(() => {
    const promiseAll = async () => {
      dispatch(startLoading());
      const [companyData, teamsData] = await Promise.all([
        getOrganization(),
        getTeams(),
      ]);
      console.log("Org: ", companyData);
      console.log("Teans: ", teamsData);
      setCompanyData(companyData.organization);
      setTeamsData(teamsData?.teams);
      setUserData(companyData.organization_users);
      dispatch(endLoading());
    };
    promiseAll();
  }, []);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setInviteModal(false);
    }
  };

  const handleMoveUser = async (userId: string, newTeamId: string) => {
    setUserData((prevUsers) =>
      prevUsers.map((user) =>
        user.user_id === userId ? { ...user, team_id: newTeamId } : user
      )
    );
    // Send request to backend to update user's team_id
    let response = await putSpecificUser(userId, { team_id: newTeamId });
  };

  return (
    <>
      <OrganizationWrapper>
        <div style={{ display: "flex" }}>
          <div className="method-select-wrapper">
            Scoring method:
            <DropDown>
              <option>{companyData?.method_name}</option>
            </DropDown>
          </div>
          {user && user.user_role === "admin" && (
            <button
              className="manage-teams"
              onClick={() => setShowTeams(!showTeams)}
            >
              {showTeams ? "Manage Org" : "Manage Teams"}
            </button>
          )}
        </div>
        {!showTeams ? (
          <>
            <div className="user-table-title">
              <h3>
                {companyData && companyData.organization_name} Organization
                Members
              </h3>
              <button className="invite" onClick={() => setInviteModal(true)}>
                Invite Member
              </button>
            </div>
            <UserTableWrapper>
              <UserRowWrapper header={true}>
                <div className="profile-icon"></div>
                <p className="name">Name</p>
                <p className="role">Role</p>
                <p className="email">Email</p>
                <p className="active">Status</p>
                <p className="team">Team</p>
              </UserRowWrapper>
              <hr />
              {userData &&
                userData.map((teamUser: User, index: number) => (
                  <UserRowWrapper
                    key={index}
                    isCurrentUser={user.email === teamUser.email}
                  >
                    <div className="profile-icon">
                      {user.profile_pic_url ? (
                        <img src={teamUser.profile_pic_url} />
                      ) : (
                        <h2>
                          {teamUser.first_name && teamUser.first_name[0]}
                          {teamUser.last_name && teamUser.last_name[0]}
                        </h2>
                      )}
                    </div>
                    <p className="name">
                      {teamUser.first_name} {teamUser.last_name}
                    </p>
                    <p className="role">
                      {teamUser.user_role
                        ? teamUser.user_role.charAt(0).toUpperCase() +
                          teamUser.user_role.slice(1)
                        : "Rep"}
                    </p>
                    <p className="email">{teamUser.email}</p>
                    <p className="active">
                      {teamUser.registered ? "Active" : "Invited"}
                    </p>
                    <p className="team">
                      {companyData && companyData.organization_name} Team
                    </p>
                    <div className="close-wrapper">
                      {!teamUser.registered ? (
                        <div
                          className="svg-wrapper"
                          onClick={() => deleteRegUser(teamUser.email)}
                        >
                          <Trash />
                        </div>
                      ) : (
                        <div className="space"></div>
                      )}
                    </div>
                  </UserRowWrapper>
                ))}
            </UserTableWrapper>
          </>
        ) : (
          <TeamsWrapper>
            <>
              <div className="user-table-title">
                <h3>Unassigned Members</h3>
                <button className="invite" onClick={() => setInviteModal(true)}>
                  Add Team
                </button>
              </div>
              <UserTable
                teamDelete={handleDeleteTeam}
                onMoveUser={handleMoveUser}
                teamId={""}
                user={user}
                users={userData.filter((user) => !user.team_id)}
              />
            </>
            {teamsData &&
              teamsData.map((team: Team, index: number) => (
                <>
                  <div className="user-table-title">
                    <h3>{team.team_name} Members</h3>
                    {/* <button className="invite" onClick={() => setInviteModal(true)}>
                      Invite Member
                    </button> */}
                  </div>
                  <UserTable
                    teamDelete={handleDeleteTeam}
                    onMoveUser={handleMoveUser}
                    teamId={team.team_id}
                    user={user}
                    users={userData.filter(
                      (user) => user.team_id === team.team_id
                    )}
                  />
                </>
              ))}
          </TeamsWrapper>
        )}
        {inviteModal && (
          <ModalWrapper onClick={(e) => handleClickOutside(e)}>
            <InviteModal ref={modalRef}>
              {!showTeams ? (
                <>
                  <h1>Invite Teammate</h1>
                  <InputWrapper className="input-wrapper">
                    <Label>Email</Label>
                    <Input
                      type="text"
                      placeholder="Please enter the company email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputWrapper>
                  <InputWrapper className="input-wrapper">
                    <Label>Role</Label>
                    <DropDown
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value={"User"}>User</option>
                      <option value={"Admin"}>Admin</option>
                    </DropDown>
                  </InputWrapper>
                  <InputWrapper className="input-wrapper">
                    <Label>Send a personal message</Label>
                    <TranscriptInput
                      value={message}
                      className="message"
                      placeholder="Hi John, I'm inviting you to leadoff..."
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </InputWrapper>
                  <button
                    onClick={handleInvite}
                    className="submit-button"
                    type="submit"
                  >
                    Send Invite
                  </button>
                </>
              ) : (
                <>
                  <h1>Add Team</h1>
                  <InputWrapper className="input-wrapper">
                    <Label>Team Name</Label>
                    <Input
                      type="text"
                      placeholder="New Team"
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                    />
                  </InputWrapper>
                  <button
                    onClick={handleAddTeam}
                    className="submit-button"
                    type="submit"
                  >
                    Add New Team
                  </button>
                </>
              )}
            </InviteModal>
          </ModalWrapper>
        )}
      </OrganizationWrapper>
    </>
  );
};

export default CompanySettings;
