import React, { FC, useEffect, useState } from "react"
import {
  DealCoachWrapper,
  PrepromptsWrapper,
  SideBar,
} from "./DealCoach.styled"
import { getDealCoachPrePrompts } from "api/helpers"
import { useDispatch } from "react-redux"
import { startLoading, endLoading } from "state/appActions"
import { getDealsQuick } from "api/helpers"
import PageWrapper from "components/reusable/PageWrapper/PageWrapper"
import DealCoachChat from "components/DealCoachChat/DealCoachChat"
import { FloatingWrapper } from "styles/shared_styled_comps/components.styled"
import { Deal as DealDetails } from "types"
import { DropDown } from "styles/shared_styled_comps/forms.styled"
import SearchBar from "components/reusable/SearchBar/SearchBar"
import { Preprompt, QuickDeal } from "types"

interface DealCoachProps {}

const DealCoach: FC<DealCoachProps> = () => {
  let dispatch = useDispatch()
  const [deals, setDeals] = useState<any>()
  const [activeDealId, setActiveDealId] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const fetchDealsData = async () => {
    dispatch(startLoading())
    let response = await getDealsQuick()
    dispatch(endLoading())
    // Ensure to on ly display 10 at a time
    setDeals(response)
  }

  useEffect(() => {
    fetchDealsData()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveDealId(event.target.value)
  }

  return (
    <PageWrapper pageTitle="Coaching">
      {/* <PrepromptsWrapper>
        {preprompts &&
          preprompts.map((preprompt: Preprompt, index: number) => (
            <Preprompt key={index}>{preprompt.frontend_text}</Preprompt>
          ))}
      </PrepromptsWrapper> */}
      <DealCoachWrapper>
        <div className="top-wrapper">
          <div className="search-wrapper">
            <SearchBar
              value={searchValue}
              updateFunction={setSearchValue}
            ></SearchBar>
          </div>
        </div>
        <FloatingWrapper width={"1200px"} height={"710px"}>
          <DealCoachChat
            deals={deals}
            deal_id={activeDealId}
            height={"90%"}
            width={"1000px"}
          />
        </FloatingWrapper>
      </DealCoachWrapper>
    </PageWrapper>
  )
}

export default DealCoach
