import {
  FC,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import moment from "moment";
import {
  DealPageWrapper,
  Deal as DealDiv,
  CategoryWrapper,
  DealHeader,
  Category as CategoryDiv,
  Feedback,
  TabsWrapper,
  ScoreBox,
  FloatingBox,
  Tab,
  WidgetsWrapper,
  ScoreBar,
  CircleScore,
  Title,
  CheckboxInput,
  CheckboxLabel,
  StrikeThroughText,
  ScoreLoader,
  SummaryLoader,
} from "./DealPage.styled";
import {
  FloatingWrapper,
  GradientText,
} from "styles/shared_styled_comps/components.styled";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getDeal,
  putDeal,
  putDealRecommendation,
  getCalls,
  postGeneralFeedback,
} from "api/helpers";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading, setError } from "state/appActions";
import AddDeal from "components/AddDeal/AddDeal";
import DealSummary from "components/DealSummary/DealSummary";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import { ScoreBox as TotalScoreBox } from "../DealSummary/DealSummary.styled";
import { DropDown } from "styles/shared_styled_comps/forms.styled";
import ScoreBarChart from "components/widgets/ScoreBarChart/ScoreBarChart";
import DealCoachChat from "components/DealCoachChat/DealCoachChat";
import CheckWithCircle from "static/svgs/CheckWithCircle";
import Currency from "static/svgs/Currency";
import Cycle from "static/svgs/Cycle";
import CompanyBreakdown from "components/CompanyBreakdown/CompanyBreakdown";
import Upload from "static/svgs/Upload";
import Share from "static/svgs/Share";
import ShareModal from "components/Share/Share";
import {
  MyObject,
  Contact,
  Call,
  Deal,
  Recommendation,
  Category,
  GeneralFeedback,
} from "types";
import {
  AddDealWrapper,
  AddDealModal,
} from "components/SideBar/SideBar.styled";
import ToolTip from "components/ToolTip/ToolTip";
import { Position } from "@reactour/tour";

interface DealPageProps {
  tourStep?: number;
  setTourStep?: Dispatch<SetStateAction<number>>;
  tourPosition?: Position;
  setTourPosition?: Dispatch<SetStateAction<Position>>;
  setTourIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const tabs = ["Summary", "Recommendations", "Pre-call Prep", "Deal Coach"];

const DealPage: FC<DealPageProps> = ({
  tourStep,
  setTourStep,
  setTourIsOpen,
  tourPosition,
  setTourPosition,
}) => {
  let { dealID } = useParams();
  let dispatch = useDispatch();
  const [data, setData] = useState<Deal>();
  const [calls, setCalls] = useState<Call[]>();
  const [currentCallId, setCurrentCallId] = useState<string>();
  const modalRef = useRef<HTMLDivElement>(null);
  const crmTipRef = useRef<HTMLDivElement>(null);
  const crmContainerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<{ send: (message: string) => void }>(null);
  const [addCall, setAddCall] = useState(false);
  const [share, setShare] = useState(false);
  const [mainContact, setMainContact] = useState<Contact>();
  const [activeTab, setTab] = useState(0);
  const [generalFeedBack, setGeneralFeedBack] = useState<GeneralFeedback[]>([]);
  const [activeTabs, setActiveTabs] = useState(tabs);
  const [currentStage, setCurrentStage] = useState("");
  const [dealId, setDealId] = useState("");
  const [messagesend, setMessagsesend] = useState("");
  const [connectCRM, setConnectCRM] = useState(false);
  const [nextSteps, setNextSteps] = useState<MyObject>({});
  const isLoading = useSelector((state: any) => state.app.isLoading);
  const timeoutRef = useRef<number | null>(null);
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const newQuery = new URLSearchParams(query.toString());
  const retry = query.get("retry");

  const stages = useSelector((state: any) => state.app.stages);
  const user = useSelector((state: any) => state.auth.user);

  const hideCRMTip = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setConnectCRM(false);
      timeoutRef.current = null;
    }, 300);
  };

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setAddCall(false);
      setShare(false);
    }
  };

  const handleCallChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentCallId(event.target.value);
    // Update the data to show to the current call's data
    let newData = data;
    let currentCall: Call | undefined = calls?.find(
      (call) => call.call_id === event.target.value
    );
    setNextSteps({});
    if (currentCall && newData) {
      let stats = currentCall.stats;
      let nextStepsObject: MyObject = {};
      newData.overall_deal_score = currentCall.overall_deal_score;
      newData.highest_scores.forEach((category: Category, index: number) => {
        let categoryStats = stats[category.category_name];
        if (categoryStats) {
          category.feedback = categoryStats.feedback;
          if (categoryStats.scores.length > 0) {
            category.highest_score = categoryStats.scores[0].category_score;
          }
        }
        if (categoryStats.recommendations) {
          category.recommendations = categoryStats.recommendations;
          categoryStats.recommendations.map(
            (recommendation: Recommendation) => {
              nextStepsObject[recommendation.rec_id] = recommendation.completed;
            }
          );
        }
      });
      setNextSteps(nextStepsObject);
    }
    setData(newData);
    console.log("New data", newData);
  };

  const handleMessageSend = async (message: string) => {
    // Swap to the deal coach tab
    setTab(3);
    // Set data to trigger a message send
    if (message !== "" && childRef && childRef.current) {
      childRef.current.send(message);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const checked = e.target.checked;
    // Update rec on backend
    putDealRecommendation(dealID || "", key, checked);
    // Update rec on frontend
    setNextSteps((prevObject) => ({
      ...prevObject,
      [key]: checked,
    }));
  };

  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStage = event.target.value;
    // Handle the selected value
    setCurrentStage(selectedStage);
    putDeal({ deal_stage: selectedStage }, dealId);
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setConnectCRM(true);
  };

  const handleMouseLeave = () => {
    hideCRMTip();
  };
  useEffect(() => {
    if (crmContainerRef.current) {
      const container = crmContainerRef.current;
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [crmContainerRef.current]);

  useEffect(() => {
    if (crmTipRef.current) {
      const container = crmTipRef.current;
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [crmTipRef.current]);

  function addLineBreaks(text: string): string {
    const pattern = /(\d+\.)|(-\s)/g;
    // Substitute the pattern with a line break followed by the matched group
    let modifiedText = text.replace(pattern, "\n$1$2");
    // Remove any leading line breaks that may have been added
    modifiedText = modifiedText.replace(/^\n/, "");
    return modifiedText;
  }

  useEffect(() => {
    const fetchDealData = async () => {
      dispatch(startLoading());
      const [data, generalFeedBackResponse] = await Promise.all([
        getDeal(dealID),
        postGeneralFeedback({ deal_id: dealID || "" }),
      ]);
      // Deal data could not be found
      if (!data) {
        dispatch(
          setError(
            "Deal not found. You could be trying to access a deal that is not available to you."
          )
        );
      }
      if (generalFeedBackResponse?.gen_feedback?.gen_feedback) {
        const genFeedback =
          generalFeedBackResponse?.gen_feedback?.gen_feedback.map(
            (feedback: GeneralFeedback) => ({
              ...feedback,
              value: addLineBreaks(feedback.value),
            })
          );
        setGeneralFeedBack(genFeedback);
      } else {
        const genFeedback = generalFeedBackResponse?.gen_feedback.map(
          (feedback: GeneralFeedback) => ({
            ...feedback,
            value: addLineBreaks(feedback.value),
          })
        );
        setGeneralFeedBack(genFeedback);
      }
      console.log("Deal data:", data);
      console.log("General Feedback:", generalFeedBackResponse);
      // To handle refreshing for the general feedback
      // if (!retry) {
      //   newQuery.set('retry', '1');
      //   navigate({ search: newQuery.toString() });
      //   window.location.reload();
      // }
      if (
        user &&
        !user?.completed_intro_tour &&
        tourStep &&
        setTourIsOpen &&
        tourStep > 0
      ) {
        setTourIsOpen(true);
      }
      dispatch(endLoading());
      setData(data ? data : { message: "No data" });
      if (data?._id) {
        setDealId(data._id);
      }
      if (data?.highest_scores?.length > 0) {
        let nextStepsObject: MyObject = {};
        data.highest_scores.forEach((category: Category, index: number) => {
          if (category?.recommendations) {
            category.recommendations.map((recommendation: Recommendation) => {
              nextStepsObject[recommendation.rec_id] = recommendation.completed;
            });
          }
        });
        setNextSteps(nextStepsObject);
      } else {
        // No score data, which means only precall prep was generated.
        setActiveTabs(["Pre-call Prep"]);
        setTab(2);
      }
      if (data?.deal_stage) {
        setCurrentStage(data.deal_stage);
      }
      if (data?.contacts) {
        setMainContact(
          data.contacts.find(
            (contact: Contact) => contact.speaker_type === "buyer"
          )
        );
      }
      let callResponse = await getCalls(dealID);
      console.log("Call data:", callResponse);
      if (callResponse) {
        setCalls(callResponse?.calls);
      }
    };
    fetchDealData();
  }, [dealID]);

  const renderTabContent = () => {
    return (
      <>
        <div style={{ display: activeTab === 0 ? "contents" : "none" }}>
          {activeTabs.includes("Summary") ? (
            <DealSummary
              categories={data?.highest_scores}
              contacts={data?.contacts}
              stage={currentStage}
              stages={stages}
              generalFeedback={generalFeedBack}
              handleMessageSend={handleMessageSend}
            />
          ) : (
            <div onClick={() => setAddCall(true)} className="upload">
              <div className="svg-wrapper">
                <Upload />
              </div>
              <GradientText>Upload a Call to Get Started</GradientText>
            </div>
          )}
        </div>
        <div style={{ display: activeTab === 1 ? "contents" : "none" }}>
          {activeTabs.includes("Recommendations") ? (
            <>
              <p className="white-underline">Next Steps</p>
              <DealDiv>
                <CategoryWrapper>
                  {data &&
                    data.highest_scores.map(
                      (category: Category, categoryIndex: number) => (
                        <CategoryDiv key={categoryIndex}>
                          <div className="title-wrapper">
                            <ScoreBox score={category.highest_score}>
                              {category.category_name[0]}
                            </ScoreBox>
                            <h1>{category.category_name}</h1>
                          </div>
                          {category.recommendations &&
                            category.recommendations.map(
                              (
                                recommendation: Recommendation,
                                index: number
                              ) => (
                                <div key={index} className="flex-wrapper">
                                  <CheckboxInput
                                    type="checkbox"
                                    checked={nextSteps[recommendation.rec_id]}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        recommendation.rec_id
                                      )
                                    }
                                    id={recommendation.rec_id}
                                  />
                                  <CheckboxLabel
                                    className="box"
                                    htmlFor={recommendation.rec_id}
                                  >
                                    <CheckWithCircle />
                                  </CheckboxLabel>
                                  {nextSteps[recommendation.rec_id] ? (
                                    <StrikeThroughText>
                                      {recommendation.text}
                                    </StrikeThroughText>
                                  ) : (
                                    <p>{recommendation.text}</p>
                                  )}
                                </div>
                              )
                            )}
                        </CategoryDiv>
                      )
                    )}
                </CategoryWrapper>
              </DealDiv>
            </>
          ) : (
            <div onClick={() => setAddCall(true)} className="upload">
              <div className="svg-wrapper">
                <Upload />
              </div>
              <GradientText>Upload a Call to Get Started</GradientText>
            </div>
          )}
        </div>
        <div style={{ display: activeTab === 2 ? "contents" : "none" }}>
          <CompanyBreakdown
            company={
              data?.companies && data?.companies?.length > 0
                ? data.companies[0]
                : null
            }
            handleMessageSend={handleMessageSend}
          />
        </div>
        <div style={{ display: activeTab === 3 ? "contents" : "none" }}>
          <DealCoachChat ref={childRef} deal_id={dealId} height={"83%"} />
        </div>
      </>
    );
  };

  const handleSelectTab = (index: number) => {
    if (!user?.completed_intro_tour && tourStep && setTourStep) {
      setTab(index);
      setTourStep(tourStep + 1);
    } else {
      setTab(index);
    }
  };

  useEffect(() => {
    const handleBack = () => {
      if (tourStep === 9) {
        setTab(0);
      }
      if (tourStep === 11) {
        setTab(1);
      }
      if (tourStep === 13) {
        setTab(2);
      }
    };
    if (!user?.completed_intro_tour) {
      handleBack();
    }
  }, [tourStep]);

  const handleDisabledTabs = (index: number) => {
    let disabled = true;
    if (user?.completed_intro_tour) {
      disabled = false;
    }
    if (tourStep === 9 && index === 1) {
      disabled = false;
    }
    if (tourStep === 11 && index === 2) {
      disabled = false;
    }
    if (tourStep === 13 && index === 3) {
      disabled = false;
    }
    return disabled;
  };

  return (
    <PageWrapper pageTitle={"Deal"}>
      <Title>
        <h2>{data && data.deal_name}</h2>
        <div className="flex">
          <h4>Method: {data && data.method_name}</h4>
          {activeTabs.includes("Recommendations") && (
            <div onClick={() => setShare(true)} className="share">
              Share
              <Share />
            </div>
          )}
        </div>
      </Title>
      {!isLoading ? (
        <ScoreBar>
          {activeTabs.includes("Recommendations") && (
            <>
              <div className="score-circle-wrapper">
                <CircleScore
                  index={0}
                  score={data && data.overall_deal_score / 10}
                >
                  {data && data.overall_deal_score}
                  <span> /100</span>
                </CircleScore>
                <p>Overall</p>
              </div>
              {data?.highest_scores.map((category: Category, index: number) => (
                <div className="score-circle-wrapper">
                  <CircleScore index={index + 1} score={category.highest_score}>
                    {category.highest_score * 10}
                  </CircleScore>
                  <p>{category.category_name}</p>
                </div>
              ))}
            </>
          )}
        </ScoreBar>
      ) : (
        <ScoreLoader></ScoreLoader>
      )}
      <DealPageWrapper className="deal-page">
        <WidgetsWrapper>
          <div>
            <DropDown
              value={currentCallId}
              onChange={(e) => handleCallChange(e)}
            >
              {calls?.map((call: Call, index: number) => (
                <option value={call.call_id}>
                  {call.call_date
                    ? moment(call.call_date).format("MMM DD, YYYY")
                    : moment(call.timestamp).format("MMM DD, YYYY")}
                </option>
              ))}
            </DropDown>
            {currentCallId &&
              currentCallId !== data?.most_recent_call.call_id && (
                <p className="error">You are currently viewing a past call</p>
              )}
            <p>Select call to update deal details</p>
          </div>
          <div
            className="flex-wrapper"
            ref={crmContainerRef}
            style={{ cursor: user.salesforce_connection ? "" : "pointer" }}
          >
            <FloatingBox>
              <div className="title-wrapper">
                <div className="svg-wrapper">
                  <Currency />
                </div>
                <h4>Deal Value</h4>
              </div>
              <p>_________</p>
            </FloatingBox>
            <FloatingBox className="deal-cycle">
              <div className="title-wrapper">
                <div className="svg-wrapper">
                  <Cycle />
                </div>
                <h4>Deal Cycle</h4>
              </div>
              <p>_________</p>
            </FloatingBox>
            {connectCRM && (
              <ToolTip
                ref={crmTipRef}
                boldText={
                  "Integrate your favorite tools to get the most out of your deal coach!"
                }
                text={
                  "We will automatically retrieve deal information to help you stay ahead. "
                }
                buttonText="Integrate CRM"
                pointSide="left"
                buttonFunction={() => navigate("/app/settings/integrations")}
              />
            )}
          </div>
          <hr />
          <div className="contact-wrapper">
            <h3>Contact</h3>
            <p>Name: {mainContact?.speaker_name}</p>
            <p>Position: {mainContact?.speaker_position}</p>
          </div>
          <div className="next-call-wrapper">
            <h3>Next call:</h3>
            <p>NOT SCHEDULED</p>
          </div>
          <hr />
          <div className="stage-wrapper">
            <h3>Deal Stage</h3>
            <DropDown value={currentStage} onChange={(e) => changeHandler(e)}>
              {stages.map((stage: string, index: number) => (
                <option key={index} value={stage}>
                  {stage}
                </option>
              ))}
            </DropDown>
          </div>
          {data?.companies && data.companies.length > 0 && (
            <div className="value-prop">
              <h3>Value Proposition</h3>
              <p>{data?.companies[0]?.value_proposition}</p>
            </div>
          )}
          {/* <button>Transcripts</button> */}
          {/* <div className="chart-wrapper">
            <ScoreBarChart categories={data?.highest_scores} />
          </div> */}
        </WidgetsWrapper>
        <div className="left-wrapper">
          <FloatingWrapper
            className="floating-wrapper-a"
            height={"715px"}
            width={"880px"}
          >
            <DealHeader>
              <TabsWrapper className="deal-tabs-wrapper">
                {tabs.map((tab: string, index: number) => {
                  const tabClass = tab.toLowerCase().replace(/ /g, "-");
                  return (
                    <>
                      <Tab
                        active={activeTab === index}
                        key={index}
                        onClick={() => handleSelectTab(index)}
                        className={tabClass}
                        disabled={handleDisabledTabs(index)}
                      >
                        {tab}
                      </Tab>
                      {index !== tabs.length - 1 && <hr />}
                    </>
                  );
                })}
              </TabsWrapper>
            </DealHeader>
            {!isLoading ? (
              <>{renderTabContent()}</>
            ) : (
              <SummaryLoader></SummaryLoader>
            )}
          </FloatingWrapper>
        </div>
        {addCall && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <AddDeal setModal={setAddCall} currentDealId={dealID} />
            </AddDealModal>
          </AddDealWrapper>
        )}
        {share && (
          <AddDealWrapper onClick={(e) => handleClickOutside(e)}>
            <AddDealModal ref={modalRef}>
              <ShareModal setModal={setAddCall} currentDealId={dealID} />
            </AddDealModal>
          </AddDealWrapper>
        )}
      </DealPageWrapper>
    </PageWrapper>
  );
};

export default DealPage;
